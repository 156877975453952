export const GET_FORM = 'forms/getForm'
export const GET_FORM_ANSWER = 'forms/getFormAnswer'
export const GET_FORM_ANSWER_INDIVIDUAL = 'forms/getFormAnswerIndividual'
export const GET_FORM_ANSWER_AVG = 'forms/getFormAnswerAvg'
export const GET_FORM_ANSWER_SID_GID = 'forms/getFormAnswerSidGid'
export const GET_FORM_ANSWER_GID = 'forms/getFormAnswerGid'
export const GET_FORM_ANSWER_GID_AVG = 'forms/getFormAnswerGidAvg'
export const GET_FORM_ANSWER_GID_STUDENT_NAME = 'forms/getFormAnswerGidStudentsName'
export const CREATE_FORM_ANSWER = 'forms/createFormAnswer'
export const UPDATE_FORM_ANSWER = 'forms/updateFormAnswer'
export const GET_FORM_ANSWER_HAS_COMPLETED = 'forms/getFormAnswerHasCompleted'
