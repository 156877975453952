import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Typography from '@mui/material/Typography'
// import ReportTopBar from '@/components/reportTopBar'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
// import Button from '@mui/material/Button'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// import DownloadIcon from '@mui/icons-material/Download'
import IconButton from '@mui/material/IconButton'

import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined'
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import TableReport from '@/components/reports/tableReport'
import DialReport from '@/components/reports/dialReport'
import ChartReport from '@/components/reports/chartReport'
import RecomReport from '@/components/reports/recomReport'
import BoxPlotTeacherReport from '@/components/reports/boxplotTeacherReport'
import StackedTeacherReport from '@/components/reports/stackedTeacherReport'

import { REDUCER_STATE } from '@/constants/store'
import { getForm, getFormAnswerGid } from '@/store/actions/form'

const TeacherReportContainer = (props) => {
  const [openSideBar, setOpenSideBar] = useState(true)
  const dispatch = useDispatch()
  const { gid } = props

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const isGettingForm = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const prevIsGettingForm = useRef(isGettingForm)

  const [reportSelection, setReportSelection] = useState({
    reportName: 'Teacher Reports Page'
  })
  const [anchorEl, setAnchorEl] = useState()
  const openTopBar = Boolean(anchorEl)

  const handleClickSideBar = () => {
    setOpenSideBar(!openSideBar)
  }
  const handleClickTopBar = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseTopBar = () => {
    setAnchorEl(null)
  }

  const renderReports = () => {
    let component
    switch (reportSelection.reportName) {
      case 'tablerep':
        component = <TableReport />
        break
      case 'dialrep':
        component = <DialReport />
        break
      case 'chartrep':
        component = <ChartReport />
        break
      case 'recomrep':
        component = <RecomReport />
        break
      case 'boxplotrep':
        component = <BoxPlotTeacherReport gid={gid} />
        break
      case 'stackedrep':
        component = <StackedTeacherReport gid={gid} />
        break
      default:
        component = (
          <Box>
            <Typography variant='h5' sx={{ marginTop: '20px', marginLeft: '20px' }}>
              Instructions
            </Typography>
            <Box
              sx={{
                margin: '20px',
                padding: '20px',
                WebkitBoxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)',
                boxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)'
              }}
            >
              <Typography variant='h6' sx={{ marginLeft: '20px' }}>
                You can choose the reports you wish to display.
              </Typography>
            </Box>
          </Box>
        )
    }
    return component
  }
  useEffect(() => {
    handleCloseTopBar()
  }, [setReportSelection])

  useEffect(() => {
    dispatch(getForm())
  }, [dispatch])

  useEffect(() => {
    if (form && !isGettingForm && prevIsGettingForm.current && !error) {
      if (gid) {
        dispatch(getFormAnswerGid({ formId: form._id, gid: gid }))
      }
    }
    prevIsGettingForm.current = isGettingForm
  }, [dispatch, error, form, gid, isGettingForm])

  return (
    <Box sx={{ width: '100%', margin: (theme) => theme.spacing(1) }} style={{ overflow: 'auto' }}>
      {/* Report Side Bar */}
      {/* <ReportTopBar /> */}
      <Box>
        <Box
          sx={{
            padding: '15px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#f2f8ff'
          }}
        >
          <Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <IconButton onClick={handleClickTopBar}>
                <MenuIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={openTopBar}
                onClose={handleCloseTopBar}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem
                  onClick={() => {
                    setReportSelection({
                      reportName: 'tablerep'
                    })
                    handleCloseTopBar()
                  }}
                  sx={{ display: 'none' }}
                >
                  Table
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setReportSelection({
                      reportName: 'dialrep'
                    })
                    handleCloseTopBar()
                  }}
                  sx={{ display: 'none' }}
                >
                  Dial
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setReportSelection({
                      reportName: 'chartrep'
                    })
                    handleCloseTopBar()
                  }}
                  sx={{ display: 'none' }}
                >
                  Chart/Reports
                </MenuItem>
                <MenuItem>BoxPlot</MenuItem>
                <MenuItem>Stacked Column</MenuItem>
                <MenuItem
                  onClick={() => {
                    setReportSelection({
                      reportName: 'recomrep'
                    })
                    handleCloseTopBar()
                  }}
                >
                  Recommendations
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          {/* <Box>
            <Box sx={{ position: 'relative' }}>
              <Button variant='contained' sx={{ marginRight: '10px' }} startIcon={<DownloadIcon />}>
                Download Report
              </Button>
            </Box>
          </Box> */}
        </Box>
      </Box>
      {/* Report Side Bar */}
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            flex: 1,
            position: 'sticky',
            top: '50px',
            backgroundColor: '#f2f8ff',
            display: { xs: 'none', sm: 'flex' }
          }}
        >
          <Box className='sidebarWrapper'>
            <Box className='sidebarMenu'>
              <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: '#f2f8ff' }}
                component='nav'
                aria-labelledby='nested-list-subheader'
              >
                <ListItemButton
                  onClick={() =>
                    setReportSelection({
                      reportName: 'boxplotrep'
                    })
                  }
                >
                  <ListItemIcon>
                    <CandlestickChartOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='BoxPlot' />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    setReportSelection({
                      reportName: 'stackedrep'
                    })
                  }
                  //sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <StackedBarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary='Stacked Column' />
                </ListItemButton>

                <ListItemButton onClick={handleClickSideBar} style={{ display: 'none' }}>
                  <ListItemIcon>
                    <SupervisedUserCircleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Teacher Reports' />
                  {openSideBar ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSideBar} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding style={{ display: 'none' }}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <ArticleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Recommendations' />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 4
          }}
        >
          {renderReports()}
        </Box>
      </Box>
    </Box>
  )
}
TeacherReportContainer.propTypes = {
  gid: PropTypes.string
}
export default TeacherReportContainer
