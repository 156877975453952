const TABLE_PERCENTILES = {
  MIN: 1.0,
  MAX: 5.0,
  OUTLINE: {
    TWENTY: 2.2,
    FIFHTY: 3.0,
    EIGHTY: 3.8
  },
  ORGANIZE: {
    TWENTY: 2.0,
    FIFHTY: 2.8,
    EIGHTY: 3.6
  },
  DELIBERATE_PRACTICE: {
    TWENTY: 2.3,
    FIFHTY: 3.2,
    EIGHTY: 4.0
  },
  PRACTICE_TESTING: {
    TWENTY: 2.2,
    FIFHTY: 3.2,
    EIGHTY: 4.2
  },
  REHEARSAL: {
    TWENTY: 2.8,
    FIFHTY: 3.4,
    EIGHTY: 4.2
  },
  FEEDBACK: {
    TWENTY: 2.0,
    FIFHTY: 2.8,
    EIGHTY: 3.8
  },
  DISTRIBUTED_PRACTICE: {
    TWENTY: 2.0,
    FIFHTY: 2.8,
    EIGHTY: 3.3
  },
  MNEMONICS: {
    TWENTY: 1.6,
    FIFHTY: 2.3,
    EIGHTY: 3.2
  },
  RELATING_IDEAS: {
    TWENTY: 2.2,
    FIFHTY: 3.0,
    EIGHTY: 3.8
  },
  SEEKING_CLARITY: {
    TWENTY: 1.8,
    FIFHTY: 2.6,
    EIGHTY: 3.6
  },
  SELF_VERBALIZATION: {
    TWENTY: 1.2,
    FIFHTY: 2.2,
    EIGHTY: 3.2
  },
  SIMILARITIES_DIFFERENCES: {
    TWENTY: 2.0,
    FIFHTY: 3.0,
    EIGHTY: 3.6
  }
}

const SCALES = {
  SURFACE_ACQUIRING: {
    NAME: 'Surface Acquiring',
    SUBSCALES: {
      OUTLINE_NAME: 'Outlining',
      ORGANIZE_NAME: 'Summarising'
    }
  },
  SURFACE_CONSOLIDATION: {
    NAME: 'Surface Consolidation',
    SUBSCALES: {
      DELIBERATE_PRACTICE_NAME: 'Deliberate Practice',
      PRACTICE_TESTING_NAME: 'Practice Testing',
      REHEARSAL_NAME: 'Rehearsal',
      FEEDBACK_NAME: 'Feedback',
      DISTRIBUTED_PRACTICE_NAME: 'Distributed Practice',
      MNEMONICS_NAME: 'Mnemonics'
    }
  },
  DEEP_ACQUIRING: {
    NAME: 'Deep Acquiring',
    SUBSCALES: {
      RELATING_IDEAS_NAME: 'Relating Ideas',
      SEEKING_CLARITY_NAME: 'Seeking Clarity'
    }
  },
  DEEP_CONSOLIDATION: {
    NAME: 'Deep Consolidation',
    SUBSCALES: {
      SELF_VERBALIZATION_NAME: 'Self-Verbalization'
    }
  },
  TRANSFER: {
    NAME: 'Transfer',
    SUBSCALES: {
      SIMILARITIES_DIFFERENCES_NAME: 'Similarities & Difficulties'
    }
  }
}

const REPORTS_FOOTER = {
  FOOTER: 'Survey report generated by Science of Learning Intervention Measure (SLIM)'
}

export { TABLE_PERCENTILES, SCALES, REPORTS_FOOTER }
