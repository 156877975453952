import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getForm, checkSurveyStatus } from '@/store/actions/form'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

import { REDUCER_STATE } from '@/constants/store'
import { CONSENT_ROUTES, TEACHER_ACCESS } from '@/constants/routes'
import CopyRight from '@/components/copyRight'
import MainLogo from '@/components/mainLogo'
import VersionUI from '@/components/versionUI'

import { useQuery } from '@/libs/utils'

const LandingPage = () => {
  const history = useHistory()
  const query = useQuery()
  const dispatch = useDispatch()
  const sid = query.get('sid')
  const gid = query.get('gid')

  const isLoggedIn = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_LOGGED_IN]
  )

  const hasCompleted = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.HAS_COMPLETED]
  )

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const onClickIndividualCompletition = () => {
    const location = {
      pathname: CONSENT_ROUTES.INDIVIDUAL_CONSENT_PAGE
    }

    if (sid && gid) {
      location.search = `?sid=${sid}&&gid=${gid}`
    }

    history.push(location)
  }

  const onClickTeacherAccess = () => {
    if (isLoggedIn) {
      history.push(TEACHER_ACCESS.TEACHER_DASHBOARD)
    } else {
      history.push(TEACHER_ACCESS.LOGIN)
    }
  }

  useEffect(() => {
    if (sid && gid) {
      dispatch(getForm())
    }
  }, [dispatch, sid, gid])

  useEffect(() => {
    if (form && sid && gid) {
      dispatch(
        checkSurveyStatus({
          formId: form._id,
          sid,
          gid
        })
      )
    }
  }, [dispatch, form, sid, gid])

  return (
    <Container sx={{ height: '100vh' }} maxWidth='sm'>
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: '2em',
              mb: '2em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <MainLogo />
            <Typography
              variant='h4'
              align='center'
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            >
              Welcome to SLIM
            </Typography>
            <Box pt={2}>
              <Typography
                variant='body2'
                align='center'
                style={{ fontStyle: 'italic' }}
                sx={{ color: (theme) => theme.palette.primary.contrastText }}
              >
                {'A measure and feedback report on your learning strategies.'}
              </Typography>
              <Typography
                variant='body2'
                align='center'
                sx={{ color: (theme) => theme.palette.primary.contrastText }}
              >
                {
                  'You can complete as an “individual” or be assigned by your teacher to a class completion.'
                }
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <Box sx={{ p: 0.5, pb: 0, margin: (theme) => theme.spacing(2) }}>
            <Grid container alignItems='center'>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size='medium'
                  variant='contained'
                  onClick={onClickIndividualCompletition}
                  disabled={hasCompleted}
                  sx={{ mt: 2, mb: 1, height: '5vh', borderRadius: '10px' }}
                >
                  <Typography>Survey completion</Typography>
                </Button>
              </Grid>
              {hasCompleted && (
                <Grid item xs={12}>
                  <Box mt={1} align='center' sx={{ textAlign: 'center' }}>
                    <Typography variant='caption' color='error'>
                      You have completed your assigned survey. Thanks for participating in SLIM.
                    </Typography>
                  </Box>
                </Grid>
              )}

              {!sid && !gid && (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size='medium'
                    variant='contained'
                    color='primary'
                    onClick={onClickTeacherAccess}
                    sx={{ mt: 1, height: '5vh', borderRadius: '10px' }}
                  >
                    <Typography>Teacher access</Typography>
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <CopyRight />
              </Grid>
              <Grid item xs={12}>
                <VersionUI typoAlign='center' typoColor='textSecondary' />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
export default LandingPage
