import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch, HashRouter } from 'react-router-dom'

import Loading from '@/components/loading'
import LandingPageView from '@/views/landingPage'
import IndividualConsentPage from '@/views/individualPage'
import InstructionsPage from '@/views/instructionsPage'
import TeacherAccessLoginPage from '@/views/teacherAccess/loginPage'
import TeacherCreateAccountPage from '@/views/teacherAccess/createAccountPage'
import TeacherForgotPassPage from '@/views/teacherAccess/forgotPassPage'
import TeacherResetPassPage from '@/views/teacherAccess/forgotPassPage/resetPasswordPage'
import TeacherDashBoard from '@/views/teacherDashboard'
import ReportsDashBoardPage from '@/views/reportsPage'
import TeacherReportsDashboardPage from '@/views/reportsPage/teacherReportsPage'
import DashBoardNewGroup from '@/views/teacherDashboard/newGroup'
import DashBoardEditGroup from '@/views/teacherDashboard/editGroup'
import ProtectedRoute from '@/routes/protectedRoutes'

import {
  ROOT_ROUTES,
  CONSENT_ROUTES,
  TEACHER_ACCESS,
  REPORTS_ROUTES,
  TEACHER_DASHBOARD
} from '@/constants/routes'

const FormAnswersPage = lazy(() => import('@/views/formAnswers'))

const RootRoutes = () => {
  return (
    <HashRouter>
      <Suspense fallback={<Loading fullscreen={true} />}>
        <Switch>
          <Route path={ROOT_ROUTES.LANDING_PAGE} component={LandingPageView} />
          <Route path={CONSENT_ROUTES.INDIVIDUAL_CONSENT_PAGE} component={IndividualConsentPage} />
          <Route path={ROOT_ROUTES.FORM_ANSWERS} component={FormAnswersPage} />
          <Route path={ROOT_ROUTES.SURVEY_INSTRUCTIONS_PAGE} component={InstructionsPage} />
          <Route path={TEACHER_ACCESS.LOGIN} component={TeacherAccessLoginPage} />
          <Route path={TEACHER_ACCESS.CREATE_ACCOUNT} component={TeacherCreateAccountPage} />
          <Route path={TEACHER_ACCESS.FORGOT_PASSWORD} component={TeacherForgotPassPage} />
          <Route
            path={TEACHER_ACCESS.RESET_PASSWORD}
            exact
            sensitive
            component={TeacherResetPassPage}
          />
          <ProtectedRoute path={TEACHER_ACCESS.TEACHER_DASHBOARD} component={TeacherDashBoard} />
          <ProtectedRoute path={TEACHER_DASHBOARD.NEW_GROUP} component={DashBoardNewGroup} />
          <ProtectedRoute path={TEACHER_DASHBOARD.EDIT_GROUP} component={DashBoardEditGroup} />
          <ProtectedRoute
            path={REPORTS_ROUTES.TEACHER_REPORTS_DASHBOARD}
            component={TeacherReportsDashboardPage}
          />
          <Route path={REPORTS_ROUTES.REPORTS_DASHBOARD} component={ReportsDashBoardPage} />
          <Route path='*'>
            <Redirect to={ROOT_ROUTES.LANDING_PAGE} />
          </Route>
        </Switch>
      </Suspense>
    </HashRouter>
  )
}

export default RootRoutes
