import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { REDUCER_STATE } from '@/constants/store'
import { registerUser } from '@/store/actions/auth'
import { useError } from '@/libs/hooks'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { TEACHER_ACCESS } from '@/constants/routes'
import { ERROR_MESSAGES } from '@/constants/errors'
import { REGEX } from '@/constants/regex'
import PageLogo from '@/components/pageLogo'
import CopyRight from '@/components/copyRight'
import Loading from '@/components/loading'
import VersionUI from '@/components/versionUI'

const CreateAccount = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    role: 'Teacher',
    passwordConfirmation: ''
  })

  const isCreatingNewUser = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_CREATING_NEW_USER]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.ERROR]
  )

  const prevIsCreating = useRef(isCreatingNewUser)

  const { email, password, firstName, lastName, role } = userCredentials

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: ''
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setUserCredentials({ ...userCredentials, [name]: value })
    setFormErrors({ ...formErrors, [name]: '' })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickLogin(event)
    }
  }

  const onClickLogin = () => {
    if (validateFields()) {
      dispatch(registerUser({ email, password, firstName, lastName, role }))
    }
  }

  const validateFields = () => {
    let result = true
    // Validate First name
    let firstNameError = ''
    if (userCredentials.firstName === '') {
      firstNameError = ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'First Name' })
      result = false
    }
    // Validate Last Name
    let lastNameError = ''
    if (userCredentials.lastName === '') {
      lastNameError = ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'Last Name' })
      result = false
    }
    // Validate Email Address
    let emailError = ''
    if (userCredentials.email === '') {
      emailError = ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'Email' })
      result = false
    } else if (!REGEX.EMAIL_VALIDATOR.test(userCredentials.email)) {
      emailError = ERROR_MESSAGES.INVALID_EMAIL
      result = false
    }
    // Validate Password
    let password = ''
    if (userCredentials.password === '') {
      password = ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'Password' })
      result = false
    } else if (!REGEX.PASSWORD_VALIDATOR.test(userCredentials.password)) {
      password = ERROR_MESSAGES.INVALID_PASSWORD
      result = false
    }
    // Validate Password Confirmation
    let passwordConfirmation = ''
    if (userCredentials.password !== userCredentials.passwordConfirmation) {
      passwordConfirmation = ERROR_MESSAGES.INVALID_MATCH_PASSWORD
      result = false
    }

    setFormErrors({
      firstName: firstNameError,
      lastName: lastNameError,
      email: emailError,
      password: password,
      passwordConfirmation: passwordConfirmation
    })
    return result
  }

  useError(error)

  useEffect(() => {
    if (prevIsCreating.current && !isCreatingNewUser && !error) {
      history.push(TEACHER_ACCESS.LOGIN)
    }
    prevIsCreating.current = isCreatingNewUser
  }, [isCreatingNewUser, error, history])

  const onClickBack = () => {
    history.push(TEACHER_ACCESS.LOGIN)
  }
  return (
    <Container sx={{ height: '100vh' }} maxWidth='md'>
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: '2em',
              mb: '2em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <PageLogo />
            <Typography
              variant='h4'
              align='center'
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            >
              Create Account
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <Box sx={{ p: 0.5, margin: (theme) => theme.spacing(2) }}>
            {!isCreatingNewUser ? (
              <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='firstName'
                    label='First Name'
                    name='firstName'
                    autoComplete='given-name'
                    onChange={handleChange}
                    {...(formErrors.firstName && {
                      error: true,
                      helperText: formErrors.firstName
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='lastName'
                    label='Last name'
                    name='lastName'
                    autoComplete='family-name'
                    onChange={handleChange}
                    {...(formErrors.lastName && {
                      error: true,
                      helperText: formErrors.lastName
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='e-mail'
                    name='email'
                    autoComplete='email'
                    onChange={handleChange}
                    {...(formErrors.email && {
                      error: true,
                      helperText: formErrors.email
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    autoComplete='new-password'
                    onChange={handleChange}
                    {...(formErrors.password && {
                      error: true,
                      helperText: formErrors.password
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    name='passwordConfirmation'
                    label='Confirm Password'
                    type='password'
                    id='passwordConfirmation'
                    autoComplete='new-password'
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    {...(formErrors.passwordConfirmation && {
                      error: true,
                      helperText: formErrors.passwordConfirmation
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={onClickLogin}
                    sx={{ margin: (theme) => theme.spacing(2, 0, 1) }}
                  >
                    Sign up
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant='text' color='primary' onClick={onClickBack}>
                    Back to login
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Loading fullScreen={true} tip='Creating User...' />
            )}
            <Grid item xs={12}>
              <CopyRight />
            </Grid>
            <Grid item xs={12}>
              <VersionUI typoAlign='center' typoColor='textSecondary' />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
export default CreateAccount
