import FormApi from '@/services/api/modules/form'
import AuthApi from '@/services/api/modules/auth'
import GroupApi from '@/services/api/modules/group'
import VersionApi from '@/services/api/modules/version'

const authApi = new AuthApi()
const formApi = new FormApi()
const groupApi = new GroupApi()
const versionApi = new VersionApi()

export { formApi, authApi, groupApi, versionApi }
