import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useError } from '@/libs/hooks'
import { login } from '@/store/actions/auth'

import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import { TEACHER_ACCESS } from '@/constants/routes'
import { REDUCER_STATE } from '@/constants/store'
import { ERROR_MESSAGES } from '@/constants/errors'
import { REGEX } from '@/constants/regex'
import CopyRight from '@/components/copyRight'
import Loading from '@/components/loading'
import MainLogo from '@/components/mainLogo'
import VersionUI from '@/components/versionUI'

const LoginPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: ''
  })

  const isGettingLogin = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_GETTING_LOGIN]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.ERROR]
  )

  const prevIsLoggin = useRef(isGettingLogin)

  const { email, password } = userCredentials

  const [formErrors, setFormErrors] = useState({
    loginError: ''
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setUserCredentials({ ...userCredentials, [name]: value })
    setFormErrors({ ...formErrors, loginError: '' })
    // setloginError('')
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickLogin(event)
    }
  }

  const onClickLogin = () => {
    if (validateFields()) {
      dispatch(login({ email, password }))
    }
  }

  const validateFields = () => {
    let result = true

    // Validate Email Address & Empty password
    let loginError = ''
    if (userCredentials.email === '' || userCredentials.password === '') {
      loginError = ERROR_MESSAGES.EMPTY_FIELDS
      result = false
    } else if (!REGEX.EMAIL_VALIDATOR.test(userCredentials.email)) {
      loginError = ERROR_MESSAGES.INVALID_EMAIL
      result = false
    }

    setFormErrors({
      loginError: loginError
    })
    return result
  }

  useError(error)

  useEffect(() => {
    if (prevIsLoggin.current && !isGettingLogin && !error) {
      history.push(TEACHER_ACCESS.TEACHER_DASHBOARD)
    }
    prevIsLoggin.current = isGettingLogin
  }, [isGettingLogin, error, history])

  const onClickCreateAccount = () => {
    history.push(TEACHER_ACCESS.CREATE_ACCOUNT)
  }

  const onClickForgotPassword = () => {
    history.push(TEACHER_ACCESS.FORGOT_PASSWORD)
  }

  return (
    <Container sx={{ height: '100vh' }} maxWidth='xs'>
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: '2em',
              mb: '2em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <MainLogo />
            <Typography
              variant='h4'
              align='center'
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            >
              Login
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <Box sx={{ p: 0.5, margin: (theme) => theme.spacing(2) }}>
            {!isGettingLogin ? (
              <Grid container alignItems='center'>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='email'
                    label='e-mail'
                    name='email'
                    autoComplete='email'
                    autoFocus
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    name='password'
                    label='Password'
                    type='password'
                    id='password'
                    autoComplete='current-password'
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                  />
                  {formErrors.loginError && (
                    <Typography variant='caption' sx={{ color: '#D32F2F' }}>
                      {formErrors.loginError}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={onClickLogin}
                    sx={{ margin: (theme) => theme.spacing(2, 0, 1) }}
                  >
                    Log in
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Grid container style={{ paddingTop: 10, paddingBottom: 5 }}>
                    <Grid item xs>
                      <Link onClick={onClickForgotPassword} variant='body2'>
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      {`Don't have an account? `}
                      <Link onClick={onClickCreateAccount} variant='body2'>
                        {`Sign Up`}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CopyRight />
                </Grid>
                <Grid item xs={12}>
                  <VersionUI typoAlign='center' typoColor='textSecondary' />
                </Grid>
              </Grid>
            ) : (
              <Loading fullScreen={false} tip='Initalizing...' />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
export default LoginPage
