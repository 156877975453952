import React from 'react'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import MainLogo from '@/components/mainLogo'
import CopyRight from '@/components/copyRight'
import VersionUI from '@/components/versionUI'
import { useQuery } from '@/libs/utils'

const InvalidAccessReport = () => {
  const query = useQuery()
  const sid = query.get('sid')
  const gid = query.get('gid')

  return (
    <Container sx={{ height: '100vh' }} maxWidth='sm'>
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: '2em',
              mb: '2em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <MainLogo gid={gid} sid={sid} />
            <Typography
              variant='h4'
              align='center'
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            >
              Invalid Access
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <Box sx={{ p: 2, margin: (theme) => theme.spacing(2) }}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    padding: (theme) => theme.spacing(1),
                    backgroundColor: '#F6F6F6'
                  }}
                >
                  <Typography align='center'>
                    {`You don't have access to view your Reports. Contact your educator for more
                    information.`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <CopyRight />
            </Grid>
            <Grid item xs={12}>
              <VersionUI typoAlign='center' typoColor='textSecondary' />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default InvalidAccessReport
