import styled from 'styled-components'

const GlobalWrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  /* padding: 1.5em; */
  padding-bottom: 2em;
  background: linear-gradient(180deg, #004aad 70%, #2989d8 100%);
`

export { GlobalWrapper }
