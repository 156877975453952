import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useError } from '@/libs/hooks'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import GaugeChart from 'react-gauge-chart'
import Grid from '@mui/material/Grid'

import ReportFooter from '@/components/reportFooter'
import { REDUCER_STATE } from '@/constants/store'
import { getForm, getFormAnswerIndividual, getFormAnswerSidGid } from '@/store/actions/form'
import { abstractAverageAnswers } from '@/libs/utils'
import { SCALES } from '@/constants/reports'

// Other logic to calculate more precise percentages
// const updateDialValues = (scaleAverages) => {
//   // Gauge chart to show identify how  students score sits relative to all.
//   // Could have 3 based on cut score ranges of 1 – 2.5, 2.5 – 3.5, 3.5 – 5)

//   const dialPercents = []

//   for (let i = 0; i < scaleAverages.length; i++) {
//     dialPercents[i] = Math.round((scaleAverages[i] / 5 + Number.EPSILON) * 100) / 100
//   }

//   return dialPercents
// }

const updateDialValues = (scaleAverages) => {
  const dialPercents = []

  for (let i = 0; i < scaleAverages.length; i++) {
    if (scaleAverages[i] >= 1 && scaleAverages[i] < 2.5) {
      dialPercents[i] = 0.8
    }
    if (scaleAverages[i] >= 2.5 && scaleAverages[i] < 3.5) {
      dialPercents[i] = 0.5
    }
    if (scaleAverages[i] >= 3.5 && scaleAverages[i] <= 5) {
      dialPercents[i] = 0.2
    }
  }
  return dialPercents
}

const DialReport = (props) => {
  const dispatch = useDispatch()
  const { gid, sid, formAnswerId } = props
  const [scaleAverages, setScaleAverages] = useState([])
  const [dialPercents, setDialPercents] = useState([])
  const [studentNames, setStudentNames] = useState()
  const [reRenderDial, setReRenderDial] = useState(false)

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const formAnswer = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER]
  )

  const formAnswerSidGid = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_SID_GID]
  )

  const isGettingFormAnswer = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM_ANSWER]
  )

  const isGettingFormAnswerSidGid = useSelector(
    (state) =>
      state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM_ANSWER_SID_GID]
  )

  const chartStyle = {
    width: 120
  }

  useError(error)

  useEffect(() => {
    if (!form && !error) {
      dispatch(getForm())
      if (!formAnswer && formAnswerId) {
        dispatch(getFormAnswerIndividual({ formId: form._id, formAnswerId: formAnswerId }))
      }
      if (!formAnswerSidGid && gid && sid) {
        dispatch(getFormAnswerSidGid({ formId: form._id, gid: gid, sid: sid }))
      }
    }
  }, [dispatch, error, form, formAnswer, formAnswerId, formAnswerSidGid, gid, sid])

  useEffect(() => {
    if (formAnswer && !isGettingFormAnswer && !error) {
      setScaleAverages(abstractAverageAnswers(formAnswer, true))
      setReRenderDial(true)
    }
    if (formAnswerSidGid && !isGettingFormAnswerSidGid && !error) {
      setScaleAverages(abstractAverageAnswers(formAnswerSidGid, false))
      setReRenderDial(true)
      setStudentNames(formAnswerSidGid.studentName)
    }
  }, [
    dispatch,
    error,
    formAnswer,
    formAnswerSidGid,
    isGettingFormAnswer,
    isGettingFormAnswerSidGid
  ])

  useEffect(() => {
    if (reRenderDial) {
      setDialPercents(updateDialValues(scaleAverages))
      setReRenderDial(false)
      setScaleAverages([])
    }
  }, [reRenderDial, scaleAverages])

  return (
    <Box
      sx={{
        flex: 4
      }}
    >
      <Typography variant='h5' sx={{ marginTop: '20px', marginLeft: '20px' }}>
        Dial {studentNames ? ` - Student: ${studentNames}` : ''}
      </Typography>
      <Box px={{ xs: 0, sm: 5 }}>
        <Box
          sx={{
            margin: '20px',
            padding: '20px'
          }}
        >
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={10} sx={{ mb: 1 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                {SCALES.SURFACE_ACQUIRING.NAME}
              </Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.SURFACE_ACQUIRING.SUBSCALES.OUTLINE_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[0]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.SURFACE_ACQUIRING.SUBSCALES.ORGANIZE_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[1]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={10} sx={{ mb: 1, mt: 2 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                {SCALES.SURFACE_CONSOLIDATION.NAME}
              </Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>
                {SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DELIBERATE_PRACTICE_NAME}
              </Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[2]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>
                {SCALES.SURFACE_CONSOLIDATION.SUBSCALES.PRACTICE_TESTING_NAME}
              </Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[3]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.SURFACE_CONSOLIDATION.SUBSCALES.REHEARSAL_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[4]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.SURFACE_CONSOLIDATION.SUBSCALES.FEEDBACK_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[5]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>
                {SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DISTRIBUTED_PRACTICE_NAME}
              </Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[6]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.SURFACE_CONSOLIDATION.SUBSCALES.MNEMONICS_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[7]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={10} sx={{ mb: 1, mt: 2 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                {SCALES.DEEP_ACQUIRING.NAME}
              </Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.DEEP_ACQUIRING.SUBSCALES.RELATING_IDEAS_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[8]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.DEEP_ACQUIRING.SUBSCALES.SEEKING_CLARITY_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[9]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={10} sx={{ mb: 1, mt: 2 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                {SCALES.DEEP_CONSOLIDATION.NAME}
              </Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.DEEP_CONSOLIDATION.SUBSCALES.SELF_VERBALIZATION_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE10C']}
                arcWidth={0.2}
                percent={dialPercents[10]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
            <Grid item xs={10} sx={{ mb: 1, mt: 2 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                {SCALES.TRANSFER.NAME}
              </Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'none', sm: 'left' } }}>
              <Typography>{SCALES.TRANSFER.SUBSCALES.SIMILARITIES_DIFFERENCES_NAME}</Typography>
            </Grid>
            <Grid item xs={5} container sx={{ justifyContent: { xs: 'flex-end', sm: 'center' } }}>
              <GaugeChart
                id='gauge-chart3'
                colors={['#EA4228', '#F5CD19', '#5BE12C']}
                arcWidth={0.2}
                percent={dialPercents[11]}
                style={chartStyle}
                hideText={true}
              />
            </Grid>
          </Grid>
          <ReportFooter typofAlign='right' typofColor='#2b2b2b' />
        </Box>
      </Box>
    </Box>
  )
}

DialReport.propTypes = {
  gid: PropTypes.string,
  sid: PropTypes.string,
  formAnswerId: PropTypes.string
}

export default DialReport
