import React from 'react'
import { useHistory } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { TEACHER_ACCESS } from '@/constants/routes'

const CheckEmail = () => {
  const history = useHistory()

  const onClickSubmit = () => {
    history.push(TEACHER_ACCESS.LOGIN)
  }
  return (
    <Grid container alignItems='center' spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            padding: (theme) => theme.spacing(1),
            backgroundColor: '#F6F6F6'
          }}
        >
          <Typography align='center'>Please check your email to change your password.</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant='contained'
          color='primary'
          onClick={onClickSubmit}
          sx={{ margin: (theme) => theme.spacing(2, 0, 0) }}
        >
          Go back
        </Button>
      </Grid>
    </Grid>
  )
}

export default CheckEmail
