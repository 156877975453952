import { React, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import { REDUCER_STATE } from '@/constants/store'
import { useQuery } from '@/libs/utils'
import { getReportAccess } from '@/store/actions/group'

import Layout from '@/components/layout'
import Loading from '@/components/loading'
import ReportContainer from '@/components/reportContainer'
import InvalidAccessReport from '@/components/reportContainer/InvalidAccessReport'
import Unauthorized from '@/components/unauthorized'
import VersionUI from '@/components/versionUI'

const ReportsPage = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const sid = query.get('sid')
  const gid = query.get('gid')
  const formAnswerId = query.get('formAnswerId')

  const reportAccess = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.REPORT_ACCESS]
  )

  useEffect(() => {
    if (sid && gid) {
      dispatch(getReportAccess({ id: gid }))
    }
  }, [dispatch, gid, reportAccess, sid])

  return (
    <>
      {reportAccess === true || formAnswerId ? (
        <Container maxWidth='xl' /* sx={{ height: '100' }} */>
          <Layout title={'Reports'} isIndividual={true} />
          <Paper elevation={15} style={{ borderRadius: '8px' }}>
            <Grid container>
              <ReportContainer gid={gid} sid={sid} formAnswerId={formAnswerId} />
            </Grid>
          </Paper>
          <Grid item xs={12}>
            <VersionUI typoAlign='right' typoColor='#F4F4F4' />
          </Grid>
        </Container>
      ) : !(sid && gid) && !formAnswerId ? (
        <Unauthorized />
      ) : reportAccess === false ? (
        <InvalidAccessReport />
      ) : (
        <Loading fullScreen={true} />
      )}
    </>
  )
}
export default ReportsPage
