import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useError } from '@/libs/hooks'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import ReportFooter from '@/components/reportFooter'
import { abstractAverageAnswers } from '@/libs/utils'
import { REDUCER_STATE } from '@/constants/store'
import { TABLE_PERCENTILES, SCALES } from '@/constants/reports'
import { getForm, getFormAnswerIndividual, getFormAnswerSidGid } from '@/store/actions/form'

function createData(scale, strategy, score, des, min, tweth, fifth, eighth, max) {
  return { scale, strategy, score, des, min, tweth, fifth, eighth, max }
}

const rows = [
  createData(
    SCALES.SURFACE_ACQUIRING.NAME,
    SCALES.SURFACE_ACQUIRING.SUBSCALES.OUTLINE_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.OUTLINE.TWENTY,
    TABLE_PERCENTILES.OUTLINE.FIFHTY,
    TABLE_PERCENTILES.OUTLINE.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    '',
    SCALES.SURFACE_ACQUIRING.SUBSCALES.ORGANIZE_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.ORGANIZE.TWENTY,
    TABLE_PERCENTILES.ORGANIZE.FIFHTY,
    TABLE_PERCENTILES.ORGANIZE.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    SCALES.SURFACE_CONSOLIDATION.NAME,
    SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DELIBERATE_PRACTICE_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.DELIBERATE_PRACTICE.TWENTY,
    TABLE_PERCENTILES.DELIBERATE_PRACTICE.FIFHTY,
    TABLE_PERCENTILES.DELIBERATE_PRACTICE.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    '',
    SCALES.SURFACE_CONSOLIDATION.SUBSCALES.PRACTICE_TESTING_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.PRACTICE_TESTING.TWENTY,
    TABLE_PERCENTILES.PRACTICE_TESTING.FIFHTY,
    TABLE_PERCENTILES.PRACTICE_TESTING.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    '',
    SCALES.SURFACE_CONSOLIDATION.SUBSCALES.REHEARSAL_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.REHEARSAL.TWENTY,
    TABLE_PERCENTILES.REHEARSAL.FIFHTY,
    TABLE_PERCENTILES.REHEARSAL.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    '',
    SCALES.SURFACE_CONSOLIDATION.SUBSCALES.FEEDBACK_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.FEEDBACK.TWENTY,
    TABLE_PERCENTILES.FEEDBACK.FIFHTY,
    TABLE_PERCENTILES.FEEDBACK.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    '',
    SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DISTRIBUTED_PRACTICE_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.DISTRIBUTED_PRACTICE.TWENTY,
    TABLE_PERCENTILES.DISTRIBUTED_PRACTICE.FIFHTY,
    TABLE_PERCENTILES.DISTRIBUTED_PRACTICE.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    '',
    SCALES.SURFACE_CONSOLIDATION.SUBSCALES.MNEMONICS_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.MNEMONICS.TWENTY,
    TABLE_PERCENTILES.MNEMONICS.FIFHTY,
    TABLE_PERCENTILES.MNEMONICS.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    SCALES.DEEP_ACQUIRING.NAME,
    SCALES.DEEP_ACQUIRING.SUBSCALES.RELATING_IDEAS_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.RELATING_IDEAS.TWENTY,
    TABLE_PERCENTILES.RELATING_IDEAS.FIFHTY,
    TABLE_PERCENTILES.RELATING_IDEAS.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    '',
    SCALES.DEEP_ACQUIRING.SUBSCALES.SEEKING_CLARITY_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.SEEKING_CLARITY.TWENTY,
    TABLE_PERCENTILES.SEEKING_CLARITY.FIFHTY,
    TABLE_PERCENTILES.SEEKING_CLARITY.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    SCALES.DEEP_CONSOLIDATION.NAME,
    SCALES.DEEP_CONSOLIDATION.SUBSCALES.SELF_VERBALIZATION_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.SELF_VERBALIZATION.TWENTY,
    TABLE_PERCENTILES.SELF_VERBALIZATION.FIFHTY,
    TABLE_PERCENTILES.SELF_VERBALIZATION.EIGHTY,
    TABLE_PERCENTILES.MAX
  ),
  createData(
    SCALES.TRANSFER.NAME,
    SCALES.TRANSFER.SUBSCALES.SIMILARITIES_DIFFERENCES_NAME,
    0,
    '',
    TABLE_PERCENTILES.MIN,
    TABLE_PERCENTILES.SIMILARITIES_DIFFERENCES.TWENTY,
    TABLE_PERCENTILES.SIMILARITIES_DIFFERENCES.FIFHTY,
    TABLE_PERCENTILES.SIMILARITIES_DIFFERENCES.EIGHTY,
    TABLE_PERCENTILES.MAX
  )
]

const renderScale = (row) => {
  let render = ''
  switch (row.scale) {
    case 'Surface Acquiring':
      render = (
        <TableCell rowSpan={2} scope='row'>
          {row.scale}
        </TableCell>
      )
      break
    case 'Surface Consolidation':
      render = (
        <TableCell rowSpan={6} scope='row'>
          {row.scale}
        </TableCell>
      )
      break
    case 'Deep Acquiring':
      render = (
        <TableCell rowSpan={2} scope='row'>
          {row.scale}
        </TableCell>
      )
      break
    case '':
      render = <></>
      break
    default:
      render = <TableCell scope='row'>{row.scale}</TableCell>
      break
  }
  return render
}
const updateTableScore = (scaleAverages) => {
  for (let i = 0; i < rows.length; i++) {
    rows[i].score = parseFloat((6 - scaleAverages[i]).toFixed(1))
    if (rows[i].score >= rows[i].eighth) {
      rows[i].des = <TableCell align='right' sx={{ backgroundColor: 'green' }} />
    } else if (rows[i].score > rows[i].tweth && rows[i].score < rows[i].eighth) {
      rows[i].des = <TableCell align='right' sx={{ backgroundColor: 'yellow' }} />
    } else if (rows[i].score <= rows[i].tweth) {
      rows[i].des = <TableCell align='right' sx={{ backgroundColor: 'red' }} />
    }
  }
}

const TableReport = (props) => {
  const dispatch = useDispatch()
  const { gid, sid, formAnswerId } = props
  const [scaleAverages, setScaleAverages] = useState([])
  const [reRenderTable, setReRenderTable] = useState(false)
  const [studentNames, setStudentNames] = useState()

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const formAnswer = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER]
  )

  const formAnswerSidGid = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_SID_GID]
  )

  const isGettingFormAnswer = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM_ANSWER]
  )

  const isGettingFormAnswerSidGid = useSelector(
    (state) =>
      state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM_ANSWER_SID_GID]
  )

  useError(error)

  useEffect(() => {
    if (!form && !error) {
      dispatch(getForm())
      if (!formAnswer && formAnswerId) {
        dispatch(getFormAnswerIndividual({ formId: form._id, formAnswerId: formAnswerId }))
      }
      if (!formAnswerSidGid && gid && sid) {
        dispatch(getFormAnswerSidGid({ formId: form._id, gid: gid, sid: sid }))
      }
    }
  }, [dispatch, error, form, formAnswer, formAnswerId, formAnswerSidGid, gid, sid])

  useEffect(() => {
    if (formAnswer && !isGettingFormAnswer && !error) {
      setScaleAverages(abstractAverageAnswers(formAnswer, true))
      setReRenderTable(true)
    }
    if (formAnswerSidGid && !isGettingFormAnswerSidGid && !error) {
      setScaleAverages(abstractAverageAnswers(formAnswerSidGid, false))
      setReRenderTable(true)
      setStudentNames(formAnswerSidGid.studentName)
    }
  }, [
    dispatch,
    error,
    formAnswer,
    formAnswerSidGid,
    isGettingFormAnswer,
    isGettingFormAnswerSidGid
  ])

  useEffect(() => {
    if (reRenderTable) {
      updateTableScore(scaleAverages)
      setReRenderTable(false)
      setScaleAverages([])
    }
  }, [reRenderTable, scaleAverages])

  return (
    <Box
      sx={{
        flex: 4
      }}
    >
      <Typography variant='h5' sx={{ marginTop: '20px', marginLeft: '20px' }}>
        Table {studentNames ? ` - Student: ${studentNames}` : ''}
      </Typography>
      <Box
        sx={{
          margin: '20px',
          padding: '20px'
        }}
      >
        <TableContainer sx={{ border: 0.3 }}>
          <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Scale</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Strategy</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Score
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='left'>
                  Des
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Min
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  20th
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  50th
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  80th
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Max
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, id) => (
                <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {renderScale(row)}
                  <TableCell scope='row'>{row.strategy}</TableCell>
                  <TableCell align='right'>{row.score}</TableCell>
                  <TableCell align='right'>{row.des}</TableCell>
                  <TableCell align='right'>{row.min}</TableCell>
                  <TableCell align='right'>{row.tweth}</TableCell>
                  <TableCell align='right'>{row.fifth}</TableCell>
                  <TableCell align='right'>{row.eighth}</TableCell>
                  <TableCell align='right'>{row.max}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ReportFooter typofAlign='right' typofColor='#2b2b2b' />
      </Box>
    </Box>
  )
}

TableReport.propTypes = {
  gid: PropTypes.string,
  sid: PropTypes.string,
  formAnswerId: PropTypes.string
}

export default TableReport
