import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

//Copyright component to display information of applicacion in the footer
const CopyRight = () => {
  return (
    <Box mt={3} mb={0} pt={2} align='center' sx={{ textAlign: 'center' }}>
      <Typography variant='caption' color='textSecondary'>
        Developed by Melbourne eResearch 2021
      </Typography>
    </Box>
  )
}

export default CopyRight
