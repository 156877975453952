import { combineReducers } from '@reduxjs/toolkit'
import { createHashHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import formSlice from '@/store/reducers/form'
import authSlice from '@/store/reducers/auth'
import groupSlice from '@/store/reducers/group'
import versionslice from '@/store/reducers/version'
import { REDUCER_STATE } from '@/constants/store'

export const history = createHashHistory()
const createRootReducer = (history) =>
  combineReducers({
    [REDUCER_STATE.ROUTER.NAME]: connectRouter(history),
    [REDUCER_STATE.FORM.NAME]: formSlice.reducer,
    [REDUCER_STATE.AUTH.NAME]: authSlice.reducer,
    [REDUCER_STATE.GROUP.NAME]: groupSlice.reducer,
    [REDUCER_STATE.VERSION.NAME]: versionslice.reducer
  })

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  )
  return store
}
