import { ttl } from '@/libs/utils'

const ENDPOINTS = {
  FORMS: '/forms',
  FORM_ANSWERS: ttl`/forms/${'formId'}/form-answers`,
  FORM_ANSWER: ttl`/forms/${'formId'}/form-answers/${'formAnswerId'}`,
  FORM_ANSWER_INDIVIDUAL: ttl`/forms/${'formId'}/form-answers-individual/${'formAnswerId'}`,
  GET_FORM_ANSWER_AVG: ttl`/forms/${'formId'}/individual-averages`,
  GET_FORM_ANSWER_SID_GID: ttl`/forms/${'formId'}/form-answers-group/${'gid'}/sid/${'sid'}`,
  GET_FORM_ANSWER_GID: ttl`/forms/${'formId'}/form-answers-group/${'gid'}`,
  GET_FORM_ANSWER_GID_STUDENT_NAME: ttl`/forms/${'formId'}/form-answers-group/${'gid'}/studentNames`,
  GET_FORM_ANSWER_GID_AVG: ttl`/forms/${'formId'}/form-answers-group/${'gid'}/averages`,
  GET_CSV: ttl`/forms/${'formId'}/csv`,
  FORM_ANSWER_HAS_COMPLETED: ttl`/forms/${'formId'}/form-answers-completed`,
  AUTH_LOGIN: '/auth/login',
  GET_SESSION_STATUS: '/auth/current',
  AUTH_LOGOUT: '/auth/logout',
  AUTH_REGISTER: '/auth/register',
  EMAIL_RESET_PASSWORD: '/auth/emailResetPassword',
  RESET_PASSWORD: '/auth/resetPassword',
  CREATE_GROUP: '/groups',
  GET_GROUPS: '/groups',
  GET_GROUPS_ALL: '/groups/all',
  GET_GROUP_BY_ID: ttl`/groups/${'id'}`,
  PUT_GROUP_BY_ID: ttl`/groups/${'id'}`,
  DELETE_GROUP_BY_ID: ttl`/groups/${'id'}`,
  GET_VERSION: '/version',
  ENABLE_REPORT_BY_ID: ttl`/groups/${'id'}/report`,
  GET_REPORT_ACCESS: ttl`/groups/${'id'}/report-access`
}

export default ENDPOINTS
