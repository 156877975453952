import React from 'react'
import { useHistory } from 'react-router-dom'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { ROOT_ROUTES } from '@/constants/routes'

import MainLogo from '@/components/mainLogo'
import CopyRight from '@/components/copyRight'
import VersionUI from '@/components/versionUI'

const Unauthorized = () => {
  const history = useHistory()

  const onClickBack = () => {
    history.push(ROOT_ROUTES.LANDING_PAGE)
  }

  return (
    <Container sx={{ height: '100vh' }} maxWidth='sm'>
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: '2em',
              mb: '2em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <MainLogo />
            <Typography
              variant='h4'
              align='center'
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            >
              Error 401
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <Box sx={{ p: 2, margin: (theme) => theme.spacing(2) }}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    padding: (theme) => theme.spacing(1),
                    backgroundColor: '#F6F6F6'
                  }}
                >
                  <Typography align='center'>{`Unauthorized page.`}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={onClickBack}
                  sx={{ margin: (theme) => theme.spacing(2, 0, 0) }}
                >
                  Go back
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <CopyRight />
            </Grid>
            <Grid item xs={12}>
              <VersionUI typoAlign='center' typoColor='textSecondary' />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Unauthorized
