import { createSlice } from '@reduxjs/toolkit'
import { REDUCER_STATE } from '@/constants/store'
import { getVersion } from '@/store/actions/version'

const {
  VERSION: { FIELDS }
} = REDUCER_STATE

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.VERSION]: null,
  [FIELDS.IS_GETTING_VERSION]: false
}

const reducers = {}

const extraReducers = {
  [getVersion.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_VERSION]: true,
    [FIELDS.VERSION]: null
  }),
  [getVersion.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_VERSION]: false,
    [FIELDS.VERSION]: action.payload
  }),
  [getVersion.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_VERSION]: false,
    [FIELDS.ERROR]: action.error
  })
}

const slice = createSlice({
  name: REDUCER_STATE.VERSION.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
