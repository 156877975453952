import { React, useState } from 'react'
import { useHistory } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import { ROOT_ROUTES } from '@/constants/routes'
import { useQuery } from '@/libs/utils'

const SurveyConsent = () => {
  const history = useHistory()
  const query = useQuery()
  const sid = query.get('sid')
  const gid = query.get('gid')

  const [beginInstFlag, setbeginInstFlag] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const [radioValue, setradioValue] = useState('')

  //Function to manage states of components in page
  const handleCloseDialog = (event) => {
    setOpenDialog(false)
    setbeginInstFlag(true)
    setradioValue('')
  }

  //Manage Button state with radio button event
  const changeDisableButton = (event) => {
    if (event.target.value === 'true') {
      setbeginInstFlag(false)
      setradioValue('true')
    } else {
      setOpenDialog(true)
    }
  }

  //Functions for redirection to pages
  const onClickShowInstructions = () => {
    const location = {
      pathname: ROOT_ROUTES.SURVEY_INSTRUCTIONS_PAGE
    }

    if (sid && gid) {
      location.search = `?sid=${sid}&&gid=${gid}`
    }

    history.push(location)
  }

  const onClickgoBackToHomePage = () => {
    const location = {
      pathname: ROOT_ROUTES.LANDING_PAGE
    }

    if (sid && gid) {
      location.search = `?sid=${sid}&&gid=${gid}`
    }

    history.push(location)
  }

  return (
    <Box sx={{ p: 0.2, margin: (theme) => theme.spacing(2) }} style={{ overflow: 'auto' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: (theme) => theme.spacing(2),
              backgroundColor: '#F6F6F6'
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='body2'>
                  This 58 item set of questions will help you understand your skills in 12 major
                  strategies of learning. Upon completion, you will see how your score on each scale
                  compares to our population figures, and direct you to various sites to improve
                  your learning strategies.
                </Typography>

                <Typography variant='body2' sx={{ mt: 3 }}>
                  We would like to use your anonymized data as part of our research work to improve
                  the tool – and note NO information about you, any links to web addresses, or any
                  identification will be seen, used, or stored anywhere.
                </Typography>

                <Typography variant='body2' sx={{ mt: 3 }}>
                  Before you read the Plain Language Statement and decide whether you want to
                  participate in our study. You can say NO – and still proceed to use all the
                  features of the SLIM project.
                </Typography>

                <Typography variant='body2' sx={{ mt: 3 }}>
                  The SLIM asks for 20 - 30 minutes.
                </Typography>

                <Typography variant='body2' sx={{ mt: 3 }}>
                  It was developed using high school and university students, so some care is needed
                  when used by elementary students and future versions may modify the norms and some
                  wording for these younger participants.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              fontStyle: 'oblique',
              padding: (theme) => theme.spacing(1),
              textAlign: 'center'
            }}
          >
            <Typography variant='caption'>
              I understand that my data will be collected...
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Box>
            <RadioGroup value={radioValue} row onChange={changeDisableButton}>
              <FormControlLabel value='false' control={<Radio />} label='No' />
              <FormControlLabel value='true' control={<Radio />} label='Yes' />
            </RadioGroup>
          </Box>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{`Go back to the Home Page?`}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                By not agreeing with the consent form you will be taken to the home page. Press
                `RETURN` to stay in the page, or press `GO TO HOME PAGE` to go to the main page of
                SLIM.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClickgoBackToHomePage}>Go to home page</Button>
              <Button onClick={handleCloseDialog} autoFocus>
                return
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant='outlined'
            size='medium'
            color='primary'
            startIcon={<ArrowBackIcon />}
            sx={{ mt: 1 }}
            onClick={onClickgoBackToHomePage}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Button
            fullWidth
            variant='contained'
            size='medium'
            color='primary'
            disabled={beginInstFlag}
            sx={{ mt: 1 }}
            onClick={onClickShowInstructions}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
export default SurveyConsent
