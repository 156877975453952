import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { getSessionStatus } from '@/store/actions/auth'
import { REDUCER_STATE } from '@/constants/store'
import { TEACHER_ACCESS } from '@/constants/routes'
import { useError } from '@/libs/hooks'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import Loading from '@/components/loading'

const ProtectedRoute = (props) => {
  const dispatch = useDispatch()
  const { path, component: Component } = props

  const isLoggedIn = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_LOGGED_IN]
  )

  const isGettingSession = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_GETTING_SESSION]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.ERROR]
  )

  useError(error)

  useEffect(() => {
    dispatch(getSessionStatus())
  }, [dispatch, path])

  return (
    <Route
      exact
      path={path}
      render={() => {
        if (!isGettingSession) {
          if (isLoggedIn) return <Component {...props} />
          else return <Redirect to={TEACHER_ACCESS.LOGIN} />
        } else {
          return <Loading fullScreen={true} />
        }
      }}
    />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  path: PropTypes.string
}

export default ProtectedRoute
