import { useEffect, useRef } from 'react'
import { message } from 'antd'

const useError = (error) => {
  const previousError = useRef(error)
  useEffect(() => {
    if (error && previousError.current !== error) {
      message.error(error.message)
    }
    previousError.current = error
  }, [error])
}

export { useError }
