import { createSlice } from '@reduxjs/toolkit'
import { REDUCER_STATE } from '@/constants/store'
import {
  getForm,
  getFormAnswer,
  getFormAnswerAvg,
  getFormAnswerSidGid,
  getFormAnswerGid,
  getFormAnswerGidStudentsName,
  getFormAnswerGidAvg,
  createFormAnswer,
  updateFormAnswer,
  checkSurveyStatus
} from '@/store/actions/form'

const {
  FORM: { FIELDS }
} = REDUCER_STATE

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.FORM]: null,
  [FIELDS.FORM_ANSWER]: null,
  [FIELDS.FORM_ANSWER_AVG]: null,
  [FIELDS.FORM_ANSWER_SID_GID]: null,
  [FIELDS.FORM_ANSWER_GID]: null,
  [FIELDS.FORM_ANSWER_GID_STUDENT_NAME]: null,
  [FIELDS.FORM_ANSWER_GID_AVG]: null,
  [FIELDS.HAS_COMPLETED]: false,
  [FIELDS.HAS_SURVEY_COMPLETED]: false,
  [FIELDS.IS_GETTING_FORM]: false,
  [FIELDS.IS_GETTING_FORM_ANSWER]: false,
  [FIELDS.IS_GETTING_FORM_ANSWER_AVG]: false,
  [FIELDS.IS_GETTING_FORM_ANSWER_SID_GID]: false,
  [FIELDS.IS_GETTING_FORM_ANSWER_GID]: false,
  [FIELDS.IS_GETTING_FORM_ANSWER_GID_STUDENT_NAME]: false,
  [FIELDS.IS_GETTING_FORM_ANSWER_GID_AVG]: false,
  [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
  [FIELDS.IS_CREATING_FORM_ANSWER]: false,
  [FIELDS.IS_GETTING_SURVEY_COMPLETED]: false
}

const reducers = {}

const extraReducers = {
  [getForm.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM]: true,
    [FIELDS.FORM_ANSWER]: null
  }),
  [getForm.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.FORM]: action.payload
  }),
  [getForm.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getFormAnswer.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER]: true
  }),
  [getFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload
  }),
  [getFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getFormAnswerAvg.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER_AVG]: true
  }),
  [getFormAnswerAvg.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_AVG]: false,
    [FIELDS.FORM_ANSWER_AVG]: action.payload
  }),
  [getFormAnswerAvg.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_AVG]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getFormAnswerSidGid.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER_SID_GID]: true
  }),
  [getFormAnswerSidGid.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_SID_GID]: false,
    [FIELDS.FORM_ANSWER_SID_GID]: action.payload
  }),
  [getFormAnswerSidGid.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_SID_GID]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getFormAnswerGid.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID]: true
  }),
  [getFormAnswerGid.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID]: false,
    [FIELDS.FORM_ANSWER_GID]: action.payload
  }),
  [getFormAnswerGid.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getFormAnswerGidStudentsName.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID_STUDENT_NAME]: true
  }),
  [getFormAnswerGidStudentsName.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID_STUDENT_NAME]: false,
    [FIELDS.FORM_ANSWER_GID_STUDENT_NAME]: action.payload
  }),
  [getFormAnswerGidStudentsName.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID_STUDENT_NAME]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getFormAnswerGidAvg.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID_AVG]: true
  }),
  [getFormAnswerGidAvg.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID_AVG]: false,
    [FIELDS.FORM_ANSWER_GID_AVG]: action.payload
  }),
  [getFormAnswerGidAvg.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_FORM_ANSWER_GID_AVG]: false,
    [FIELDS.ERROR]: action.error
  }),
  [checkSurveyStatus.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_SURVEY_COMPLETED]: true,
    [FIELDS.HAS_COMPLETED]: null
  }),
  [checkSurveyStatus.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_SURVEY_COMPLETED]: false,
    [FIELDS.HAS_COMPLETED]: action.payload
  }),
  [checkSurveyStatus.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_SURVEY_COMPLETED]: false,
    [FIELDS.ERROR]: action.error
  }),
  [createFormAnswer.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING_FORM_ANSWER]: true
  }),
  [createFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload
  }),
  [createFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error
  }),
  [updateFormAnswer.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: true
  }),
  [updateFormAnswer.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
    [FIELDS.FORM_ANSWER]: action.payload
  }),
  [updateFormAnswer.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_FORM_ANSWER]: false,
    [FIELDS.ERROR]: action.error
  })
}

const slice = createSlice({
  name: REDUCER_STATE.FORM.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
