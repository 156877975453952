import { createAsyncThunk } from '@reduxjs/toolkit'
import { formApi } from '@/services/api'
import {
  GET_FORM,
  GET_FORM_ANSWER,
  GET_FORM_ANSWER_SID_GID,
  GET_FORM_ANSWER_GID,
  GET_FORM_ANSWER_AVG,
  GET_FORM_ANSWER_GID_STUDENT_NAME,
  GET_FORM_ANSWER_GID_AVG,
  CREATE_FORM_ANSWER,
  UPDATE_FORM_ANSWER,
  GET_FORM_ANSWER_HAS_COMPLETED
} from '@/store/types/form'

const getForm = createAsyncThunk(GET_FORM, async () => {
  const forms = await formApi.getForms()
  return forms[0]
})

const getFormAnswer = createAsyncThunk(GET_FORM_ANSWER, async (payload) => {
  return await formApi.getFormAnswer(payload)
})

const getFormAnswerIndividual = createAsyncThunk(GET_FORM_ANSWER, async (payload) => {
  return await formApi.getFormAnswerIndividual(payload)
})

const getFormAnswerAvg = createAsyncThunk(GET_FORM_ANSWER_AVG, async (payload) => {
  return await formApi.getFormAnswerAvg(payload)
})

const getFormAnswerSidGid = createAsyncThunk(GET_FORM_ANSWER_SID_GID, async (payload) => {
  return await formApi.getFormAnswerSidGid(payload)
})

const getFormAnswerGid = createAsyncThunk(GET_FORM_ANSWER_GID, async (payload) => {
  return await formApi.getFormAnswerGid(payload)
})

const getFormAnswerGidAvg = createAsyncThunk(GET_FORM_ANSWER_GID_AVG, async (payload) => {
  return await formApi.getFormAnswerGidAvg(payload)
})

const getFormAnswerGidStudentsName = createAsyncThunk(
  GET_FORM_ANSWER_GID_STUDENT_NAME,
  async (payload) => {
    return await formApi.getFormAnswerGidStudentsName(payload)
  }
)

const checkSurveyStatus = createAsyncThunk(GET_FORM_ANSWER_HAS_COMPLETED, async (payload) => {
  return await formApi.checkSurveyStatus(payload)
})

const createFormAnswer = createAsyncThunk(CREATE_FORM_ANSWER, async (payload) => {
  return await formApi.createFormAnswer(payload)
})

const updateFormAnswer = createAsyncThunk(UPDATE_FORM_ANSWER, async (payload) => {
  return await formApi.updateFormAnswer(payload)
})

export {
  getForm,
  getFormAnswer,
  getFormAnswerIndividual,
  getFormAnswerAvg,
  createFormAnswer,
  updateFormAnswer,
  checkSurveyStatus,
  getFormAnswerSidGid,
  getFormAnswerGid,
  getFormAnswerGidStudentsName,
  getFormAnswerGidAvg
}
