import { React } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { ROOT_ROUTES, CONSENT_ROUTES } from '@/constants/routes'

import { useQuery } from '@/libs/utils'

const SurveyInstructions = () => {
  const history = useHistory()
  const query = useQuery()
  const sid = query.get('sid')
  const gid = query.get('gid')

  //Functions for redirection to pages
  const onClickbeginQuestionnarie = () => {
    const location = {
      pathname: ROOT_ROUTES.FORM_ANSWERS
    }

    if (sid && gid) {
      location.search = `?sid=${sid}&&gid=${gid}`
    }
    history.push(location)
  }

  const onClickgoBack = () => {
    const location = {
      pathname: CONSENT_ROUTES.INDIVIDUAL_CONSENT_PAGE
    }

    if (sid && gid) {
      location.search = `?sid=${sid}&&gid=${gid}`
    }

    history.push(location)
  }

  return (
    <Box sx={{ p: 0.2, margin: (theme) => theme.spacing(2) }} style={{ overflow: 'auto' }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: (theme) => theme.spacing(2),
              backgroundColor: '#F6F6F6'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant='body2'>
                  You will be asked 58 short questions about how you typically approach your studies
                  in a given situation.
                </Typography>

                <Typography variant='body2' sx={{ mt: 2 }}>
                  For each situation, please rate how frequently you typically engage in the
                  presented learning behavior – and choose ONE of the following options:
                </Typography>
                <Box>
                  <FormControl>
                    <RadioGroup defaultValue='always' name='radio-buttons-group'>
                      <FormControlLabel
                        value='always'
                        control={<Radio size='small' />}
                        label='Always'
                      />
                      <FormControlLabel
                        value='nearly'
                        control={<Radio size='small' />}
                        label='Nearly always'
                      />
                      <FormControlLabel
                        value='often'
                        control={<Radio size='small' />}
                        label='Often'
                      />
                      <FormControlLabel
                        value='sometimes'
                        control={<Radio size='small' />}
                        label='Sometimes'
                      />
                      <FormControlLabel
                        value='rarely'
                        control={<Radio size='small' />}
                        label='Rarely or never'
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='body2'>Please provide ONE answer per question.</Typography>
                <Typography variant='body2' component='li' sx={{ mt: 1 }}>
                  If you change your mind, please change the selection before pressing the `next`
                  button at the bottom of the screen.
                </Typography>
                <Typography variant='body2' component='li' sx={{ mt: 1 }}>
                  Ensure that you have a reliable internet connection and around 20 - 30 minutes of
                  uninterrupted time.
                </Typography>
                <Typography variant='body2' component='li' sx={{ mt: 1 }}>
                  If your internet times-out or is disrupted before you click the `next` button,
                  your answers will not be saved.
                </Typography>
                <Typography variant='body2' component='li' sx={{ mt: 1 }}>
                  There are no correct answers to these questions. We are more interested in what
                  you think best describes you in a given study situation. Therefore, we ask that
                  you answer each question as honestly as possible.
                </Typography>
                <Typography variant='body2' component='li' sx={{ mt: 1 }}>
                  When you press `next` you will begin the Science of Learning Intervention Measure
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant='outlined'
            size='medium'
            color='primary'
            startIcon={<ArrowBackIcon />}
            sx={{ mt: 0 }}
            onClick={onClickgoBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Button
            fullWidth
            variant='contained'
            size='medium'
            color='primary'
            sx={{ mt: 0 }}
            onClick={onClickbeginQuestionnarie}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
export default SurveyInstructions
