import BaseApi from './base'
/**
 * This class wraps multiple group api requests to the server.
 */

class GroupApi extends BaseApi {
  /**
   * Creates a new group
   * @param {string} name
   * @param {number} openDate
   * @param {number} closeDate
   * @param {number} completionRate
   * @param {array} completionRate
   * @return {Promise<*>}
   */
  async createGroup({ name, openDate, closeDate, completionRate, members }) {
    const _self = this
    try {
      const body = {
        name,
        openDate,
        closeDate,
        completionRate,
        members
      }
      const res = await _self.client.post(_self.endpoints.CREATE_GROUP, body)

      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Retrieve a list of groups created by teacher who is currently logged in
   * @return {Promise<*>}
   */
  async getGroups() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.GET_GROUPS)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Retrieve a group by ID
   * @param {string} id
   * @return {Promise<*>}
   */
  async getGroupByid({ id }) {
    const _self = this
    const params = { populates: 'members' }
    try {
      const res = await _self.client.get(_self.endpoints.GET_GROUP_BY_ID({ id }), {
        params
      })
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Updates a group.
   * @param {string} id
   * @param {string} name
   * @param {number} openDate
   * @param {number} closeDate
   * @param {number} completionRate
   * @param {array} completionRate
   * @return {Promise<*>}
   */
  async updateGroupByid({ id, name, openDate, closeDate, completionRate, members }) {
    const _self = this
    try {
      const body = {
        name,
        openDate,
        closeDate,
        completionRate,
        members
      }
      const res = await _self.client.put(_self.endpoints.PUT_GROUP_BY_ID({ id }), body)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Enable or disable a report access.
   * @param {string} id
   * @return {Promise<*>}
   */
  async enableReportById({ id, reportAccess }) {
    const _self = this
    try {
      const body = {
        enableReport: reportAccess
      }
      const res = await _self.client.put(_self.endpoints.ENABLE_REPORT_BY_ID({ id }), body)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Get report access status.
   * @param {string} id
   * @return {Promise<*>}
   */
  async getReportAccess({ id }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.GET_REPORT_ACCESS({ id }))
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
}

export default GroupApi
