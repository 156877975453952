import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useError } from '@/libs/hooks'
import { emailResetPassword } from '@/store/actions/auth'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { TEACHER_ACCESS } from '@/constants/routes'
import { REDUCER_STATE } from '@/constants/store'
import { ERROR_MESSAGES } from '@/constants/errors'
import { REGEX } from '@/constants/regex'
import MainLogo from '@/components/mainLogo'
import CopyRight from '@/components/copyRight'
import Loading from '@/components/loading'
import CheckEmail from '@/components/checkEmail'
import VersionUI from '@/components/versionUI'

const ForgotPasswordPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [recoverEmail, setrecoverEmail] = useState({
    email: ''
  })

  const isEmailResettingPassword = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_EMAIL_RESETTING_PASSWORD]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.ERROR]
  )

  const prevIsEmailResettingPassword = useRef(isEmailResettingPassword)

  const { email } = recoverEmail

  const [formError, setFormError] = useState({
    email: ''
  })

  const [submitFlag, setsubmitFlag] = useState(false)

  const handleChange = (event) => {
    const { name, value } = event.target
    setrecoverEmail({ ...recoverEmail, [name]: value })
    setFormError({ ...formError, [name]: '' })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickSubmit(event)
    }
  }

  const onClickSubmit = () => {
    if (validateEmail()) {
      dispatch(emailResetPassword({ email }))
    }
  }

  const validateEmail = () => {
    let result = true
    let emailError = ''
    if (recoverEmail.email === '') {
      emailError = ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'Email' })
      result = false
    } else if (!REGEX.EMAIL_VALIDATOR.test(recoverEmail.email)) {
      emailError = ERROR_MESSAGES.INVALID_EMAIL
      result = false
    }
    setFormError({
      email: emailError
    })
    return result
  }

  useError(error)

  useEffect(() => {
    if (prevIsEmailResettingPassword.current && !isEmailResettingPassword && !error) {
      setsubmitFlag(true)
    }
    prevIsEmailResettingPassword.current = isEmailResettingPassword
  }, [isEmailResettingPassword, error])

  const onClickBack = () => {
    history.push(TEACHER_ACCESS.LOGIN)
  }

  return (
    <Container sx={{ height: '100vh' }} maxWidth='sm'>
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: '2em',
              mb: '2em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <MainLogo />
            <Typography
              variant='h4'
              align='center'
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            >
              Forgot Password?
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <Box sx={{ p: 2, margin: (theme) => theme.spacing(2) }}>
            {!isEmailResettingPassword ? (
              submitFlag ? (
                <CheckEmail />
              ) : (
                <Grid container alignItems='center' spacing={1}>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        padding: (theme) => theme.spacing(1),
                        backgroundColor: '#F6F6F6'
                      }}
                    >
                      <Typography align='center'>
                        Enter the email address associated with your account.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='standard'
                      margin='normal'
                      fullWidth
                      id='email'
                      label='Enter Email Address'
                      name='email'
                      autoComplete='email'
                      autoFocus
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      {...(formError.email && {
                        error: true,
                        helperText: formError.email
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth variant='contained' color='primary' onClick={onClickSubmit}>
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth variant='text' color='primary' onClick={onClickBack}>
                      Back to login
                    </Button>
                  </Grid>
                </Grid>
              )
            ) : (
              <Loading fullScreen={false} tip='Loading...' />
            )}
            <Grid item xs={12}>
              <CopyRight />
            </Grid>
            <Grid item xs={12}>
              <VersionUI typoAlign='center' typoColor='textSecondary' />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
export default ForgotPasswordPage
