import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import VisibilityIcon from '@mui/icons-material/Visibility'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import PDFMerger from 'pdf-merger-js/browser'
import recomPdf from '@/assets/SLIM_Recommendation_Reports.pdf'

// import { SCALES } from '@/constants/reports'

const SCALES_RECOM_REPORT = {
  SURFACE_ACQUISITION: {
    NAME: 'Surface Acquisition',
    SUBSCALES: {
      OUTLINE_NAME: 'Outlining',
      ORGANIZE_NAME: 'Organising'
    }
  },
  SURFACE_CONSOLIDATION: {
    NAME: 'Surface Consolidation',
    SUBSCALES: {
      DELIBERATE_PRACTICE_NAME: 'Deliberate Practice',
      DISTRUBUTED_PRATICE_NAME: 'Distributed Practice',
      HELP_SEEKING_NAME: 'Help Seeking',
      MNEMONICS_NAME: 'Mnemonics',
      PRACTICE_TESTING_NAME: 'Practice Testing (Retrieval Practice)',
      REHEARSAL_MEMORIZATION_NAME: 'Rehearsal & Memorization'
    }
  },
  DEEP_ACQUISITION: {
    NAME: 'Deep Acquisition',
    SUBSCALES: {
      RELATING_IDEAS_NAME: 'Relating Ideas',
      SEEKING_CLARITY_NAME: 'Seeking Clarity',
      SELF_VERBALIZATION_NAME: 'Self-Verbalization (Self-Questioning)'
    }
  },
  TRANSFER: {
    NAME: 'Transfer',
    SUBSCALES: {
      SIMILARITIES_DIFFERENCES_NAME: 'Similarities & Difficulties'
    }
  }
}

const RecomReport = () => {
  const [checkedList, setCheckedList] = useState([])
  const merger = new PDFMerger()

  const reporType = [
    {
      repId: 1,
      repCat: SCALES_RECOM_REPORT.SURFACE_ACQUISITION.NAME,
      repName: SCALES_RECOM_REPORT.SURFACE_ACQUISITION.SUBSCALES.OUTLINE_NAME
    },
    {
      repId: 2,
      repCat: SCALES_RECOM_REPORT.SURFACE_ACQUISITION.NAME,
      repName: SCALES_RECOM_REPORT.SURFACE_ACQUISITION.SUBSCALES.ORGANIZE_NAME
    },
    {
      repId: 3,
      repCat: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.NAME,
      repName: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.DELIBERATE_PRACTICE_NAME
    },
    {
      repId: 4,
      repCat: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.NAME,
      repName: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.DISTRUBUTED_PRATICE_NAME
    },
    {
      repId: 5,
      repCat: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.NAME,
      repName: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.HELP_SEEKING_NAME
    },
    {
      repId: 6,
      repCat: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.NAME,
      repName: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.MNEMONICS_NAME
    },
    {
      repId: 7,
      repCat: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.NAME,
      repName: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.PRACTICE_TESTING_NAME
    },
    {
      repId: 8,
      repCat: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.NAME,
      repName: SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.REHEARSAL_MEMORIZATION_NAME
    },
    {
      repId: 9,
      repCat: SCALES_RECOM_REPORT.DEEP_ACQUISITION.NAME,
      repName: SCALES_RECOM_REPORT.DEEP_ACQUISITION.SUBSCALES.RELATING_IDEAS_NAME
    },
    {
      repId: 10,
      repCat: SCALES_RECOM_REPORT.DEEP_ACQUISITION.NAME,
      repName: SCALES_RECOM_REPORT.DEEP_ACQUISITION.SUBSCALES.SEEKING_CLARITY_NAME
    },
    {
      repId: 11,
      repCat: SCALES_RECOM_REPORT.DEEP_ACQUISITION.NAME,
      repName: SCALES_RECOM_REPORT.DEEP_ACQUISITION.SUBSCALES.SELF_VERBALIZATION_NAME
    },
    {
      repId: 12,
      repCat: SCALES_RECOM_REPORT.TRANSFER.NAME,
      repName: SCALES_RECOM_REPORT.TRANSFER.SUBSCALES.SIMILARITIES_DIFFERENCES_NAME
    }
  ]

  const handleCheckboxClick = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setCheckedList([...checkedList, value])
    } else {
      setCheckedList(checkedList.filter((item) => item !== value))
    }
  }

  const handleClickOpen = async () => {
    const recommArray = []
    for (let i = 0; i < checkedList.length; i++) {
      const element = checkedList[i]
      switch (element) {
        case SCALES_RECOM_REPORT.SURFACE_ACQUISITION.SUBSCALES.OUTLINE_NAME:
        case SCALES_RECOM_REPORT.SURFACE_ACQUISITION.SUBSCALES.ORGANIZE_NAME:
          if (recommArray.indexOf(2) === -1) {
            recommArray.push(2)
          }
          break
        case SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.DELIBERATE_PRACTICE_NAME:
          recommArray.push(3)
          break
        case SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.DISTRUBUTED_PRATICE_NAME:
          recommArray.push(4)
          break
        case SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.HELP_SEEKING_NAME:
          recommArray.push(5)
          break
        case SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.MNEMONICS_NAME:
          recommArray.push(6)
          break
        case SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.PRACTICE_TESTING_NAME:
          recommArray.push(7)
          break
        case SCALES_RECOM_REPORT.SURFACE_CONSOLIDATION.SUBSCALES.REHEARSAL_MEMORIZATION_NAME:
          recommArray.push(8)
          break
        case SCALES_RECOM_REPORT.DEEP_ACQUISITION.SUBSCALES.RELATING_IDEAS_NAME:
          recommArray.push(9)
          break
        case SCALES_RECOM_REPORT.DEEP_ACQUISITION.SUBSCALES.SEEKING_CLARITY_NAME:
          recommArray.push(10)
          break
        case SCALES_RECOM_REPORT.DEEP_ACQUISITION.SUBSCALES.SELF_VERBALIZATION_NAME:
          recommArray.push(11)
          break
        case SCALES_RECOM_REPORT.TRANSFER.SUBSCALES.SIMILARITIES_DIFFERENCES_NAME:
          recommArray.push(12)
          break
        default:
          break
      }
    }
    await merger.add(recomPdf, [1])
    await merger.add(recomPdf, recommArray)

    const mergedPdf = await merger.saveAsBlob()
    const url = URL.createObjectURL(mergedPdf)

    window.open(url)
  }

  const selectItem = (e) => {
    const { checked } = e.target
    const collection = []

    if (checked) {
      for (const repor of reporType) {
        collection.push(repor.repName)
      }
    }
    setCheckedList(collection)
  }

  return (
    <Box
      sx={{
        flex: 4
      }}
    >
      <Typography variant='h5' sx={{ marginTop: '20px', marginLeft: '20px' }}>
        Recommendations
      </Typography>

      <Box
        sx={{
          margin: { xs: '10px', sm: '20px' },
          padding: '20px',
          WebkitBoxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)',
          boxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)'
        }}
      >
        <Grid container>
          <>
            <Grid item sx={{ display: 'flex', alignContent: 'right', width: '100%' }} xs={12}>
              <List sx={{ width: '100%' }}>
                <ListItem disablePadding>
                  <ListItemButton dense>
                    <ListItemIcon>
                      <Checkbox
                        checked={checkedList.length === 12 ? true : false}
                        edge='start'
                        onClick={selectItem.bind(this)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={'Select all'} />
                  </ListItemButton>
                </ListItem>
                {reporType.map((value) => {
                  const labelId = `checkbox-list-label-${value.repName}`

                  return (
                    <ListItem key={value.repId} disablePadding>
                      <ListItemButton dense>
                        <ListItemIcon>
                          <Checkbox
                            edge='start'
                            item={value}
                            value={value.repName}
                            checked={checkedList.includes(value.repName)}
                            onChange={handleCheckboxClick}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.repCat} - ${value.repName}`} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Box>
                <Button
                  variant='contained'
                  sx={{ marginRight: '10px' }}
                  startIcon={<VisibilityIcon />}
                  onClick={handleClickOpen}
                  disabled={checkedList.length === 0 ? true : false}
                >
                  View
                </Button>
              </Box>
            </Grid>
          </>
        </Grid>
      </Box>
    </Box>
  )
}
export default RecomReport
