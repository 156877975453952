import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useError } from '@/libs/hooks'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

import ReportFooter from '@/components/reportFooter'
import { SCALES } from '@/constants/reports'
import { REDUCER_STATE } from '@/constants/store'
import {
  getForm,
  getFormAnswerIndividual,
  getFormAnswerAvg,
  getFormAnswerSidGid,
  getFormAnswerGidAvg
} from '@/store/actions/form'

import { computeBoxPlot, abstractAverageAnswers } from '@/libs/utils'

HighchartsMore(Highcharts)
const AntSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}"/></svg>')`,
      left: 12
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}"/></svg>')`,
      right: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}))

const ChartReport = (props) => {
  const dispatch = useDispatch()
  const { gid, sid, formAnswerId } = props
  const [studentNames, setStudentNames] = useState()
  const [isAllSurveysSwitch, setIsAllSurveysSwitch] = useState(true)
  const [studentAnswer, setStudentAnswers] = useState([])
  const [boxPlotData, setboxPlotData] = useState([])

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const formAnswer = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER]
  )

  const formAnswerAvg = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_AVG]
  )

  const formAnswerSidGid = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_SID_GID]
  )

  const formAnswerGidAvg = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_GID_AVG]
  )

  const isGettingFormAnswerAvg = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM_ANSWER_AVG]
  )

  const isGettingFormAnswerGidAvg = useSelector(
    (state) =>
      state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM_ANSWER_GID_AVG]
  )

  const options = {
    chart: {
      type: 'boxplot',
      inverted: true
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: [
        SCALES.SURFACE_ACQUIRING.SUBSCALES.OUTLINE_NAME,
        SCALES.SURFACE_ACQUIRING.SUBSCALES.ORGANIZE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DELIBERATE_PRACTICE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.PRACTICE_TESTING_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.REHEARSAL_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.FEEDBACK_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DISTRIBUTED_PRACTICE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.MNEMONICS_NAME,
        SCALES.DEEP_ACQUIRING.SUBSCALES.RELATING_IDEAS_NAME,
        SCALES.DEEP_ACQUIRING.SUBSCALES.SEEKING_CLARITY_NAME,
        SCALES.DEEP_CONSOLIDATION.SUBSCALES.SELF_VERBALIZATION_NAME,
        SCALES.TRANSFER.SUBSCALES.SIMILARITIES_DIFFERENCES_NAME
      ],
      title: {
        text: null
      }
    },
    yAxis: {
      title: {
        // text: '1-Never &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp 2-Rarely &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp 3-Sometimes &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp 4-Often &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp 5-Always'
        text: null
      },
      min: 1,
      max: 5,
      allowDecimals: true,
      plotBands: [
        {
          color: '#ffe5e6',
          from: 1,
          to: 2.5
        },
        {
          color: '#ffffe5',
          from: 2.5,
          to: 3.5
        },
        {
          color: '#e5f6ec',
          from: 3.5,
          to: 5
        }
      ]
    },
    series: [
      {
        name: 'Class Data',
        data: boxPlotData,
        tooltip: {
          headerFormat: '<em>{point.key}</em><br/>'
        }
      },
      {
        name: 'Student Score',
        type: 'line',
        lineWidth: 0,
        data: [
          [
            SCALES.SURFACE_ACQUIRING.SUBSCALES.OUTLINE_NAME,
            parseFloat((6 - studentAnswer[0]).toFixed(1))
          ],
          [
            SCALES.SURFACE_ACQUIRING.SUBSCALES.ORGANIZE_NAME,
            parseFloat((6 - studentAnswer[1]).toFixed(1))
          ],
          [
            SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DELIBERATE_PRACTICE_NAME,
            parseFloat((6 - studentAnswer[2]).toFixed(1))
          ],
          [
            SCALES.SURFACE_CONSOLIDATION.SUBSCALES.PRACTICE_TESTING_NAME,
            parseFloat((6 - studentAnswer[3]).toFixed(1))
          ],
          [
            SCALES.SURFACE_CONSOLIDATION.SUBSCALES.REHEARSAL_NAME,
            parseFloat((6 - studentAnswer[4]).toFixed(1))
          ],
          [
            SCALES.SURFACE_CONSOLIDATION.SUBSCALES.FEEDBACK_NAME,
            parseFloat((6 - studentAnswer[5]).toFixed(1))
          ],
          [
            SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DISTRIBUTED_PRACTICE_NAME,
            parseFloat((6 - studentAnswer[6]).toFixed(1))
          ],
          [
            SCALES.SURFACE_CONSOLIDATION.SUBSCALES.MNEMONICS_NAME,
            parseFloat((6 - studentAnswer[7]).toFixed(1))
          ],
          [
            SCALES.DEEP_ACQUIRING.SUBSCALES.RELATING_IDEAS_NAME,
            parseFloat((6 - studentAnswer[8]).toFixed(1))
          ],
          [
            SCALES.DEEP_ACQUIRING.SUBSCALES.SEEKING_CLARITY_NAME,
            parseFloat((6 - studentAnswer[9]).toFixed(1))
          ],
          [
            SCALES.DEEP_CONSOLIDATION.SUBSCALES.SELF_VERBALIZATION_NAME,
            parseFloat((6 - studentAnswer[10]).toFixed(1))
          ],
          [
            SCALES.TRANSFER.SUBSCALES.SIMILARITIES_DIFFERENCES_NAME,
            parseFloat((6 - studentAnswer[11]).toFixed(1))
          ]
        ],
        marker: {
          symbol: 'diamond',
          lineWidth: 1
        },
        tooltip: {
          pointFormat: 'Your value: {point.y}'
        }
      }
    ],
    exporting: {
      enabled: false
    }
  }

  useError(error)

  //Fetch answers of individual or class if they couldn't get fetch before
  useEffect(() => {
    if (!form && !error) {
      dispatch(getForm())
      if (!formAnswer && formAnswerId) {
        dispatch(getFormAnswerIndividual({ formId: form._id, formAnswerId: formAnswerId }))
      }
      if (!formAnswerSidGid && gid && sid) {
        dispatch(getFormAnswerSidGid({ formId: form._id, gid: gid, sid: sid }))
      }
    }
  }, [dispatch, error, form, formAnswer, formAnswerId, formAnswerSidGid, gid, sid])

  //Fetch average of answers in an array of 12 positions (pages)
  useEffect(() => {
    //Fetch all completed surveys -> GETS: formAnswerAvg
    if (formAnswerId && !formAnswerAvg && !isGettingFormAnswerAvg) {
      dispatch(getFormAnswerAvg({ formId: form._id }))
    }
    //Fetch all group completed surveys -> GETS: formAnswerAvg && formAnswerGidAvg
    if (sid && gid && !formAnswerGidAvg && !isGettingFormAnswerGidAvg) {
      dispatch(getFormAnswerAvg({ formId: form._id })).then(() => {
        dispatch(getFormAnswerGidAvg({ formId: form._id, gid: gid }))
      })
    }
  }, [
    dispatch,
    form._id,
    formAnswerAvg,
    formAnswerGidAvg,
    formAnswerId,
    gid,
    isGettingFormAnswerAvg,
    isGettingFormAnswerGidAvg,
    sid
  ])

  useEffect(() => {
    // For individual
    if (formAnswerId && formAnswerAvg && !isGettingFormAnswerAvg && !error) {
      setboxPlotData(computeBoxPlot(formAnswerAvg))
      setStudentAnswers(abstractAverageAnswers(formAnswer, true))
    }
    // For class
    if (gid && formAnswerGidAvg && !isGettingFormAnswerGidAvg && !error) {
      //Boxplot calculated with class data
      //setboxPlotData(computeBoxPlot(formAnswerGidAvg))
      //Boxplot calculated with whole data
      setboxPlotData(computeBoxPlot(formAnswerAvg))
      setStudentAnswers(abstractAverageAnswers(formAnswerSidGid))
      setStudentNames(formAnswerSidGid.studentName)
    }
  }, [
    error,
    formAnswer,
    formAnswerAvg,
    formAnswerGidAvg,
    formAnswerId,
    formAnswerSidGid,
    gid,
    isGettingFormAnswerAvg,
    isGettingFormAnswerGidAvg
  ])

  const switchHandler = (event) => {
    setIsAllSurveysSwitch(event.target.checked)
    isAllSurveysSwitch
      ? setboxPlotData(computeBoxPlot(formAnswerGidAvg))
      : setboxPlotData(computeBoxPlot(formAnswerAvg))
  }

  return (
    <Box>
      <Typography variant='h5' sx={{ mt: '20px', ml: '20px', mb: '10px' }}>
        Box plot {studentNames ? ` - Student: ${studentNames}` : ''}
      </Typography>
      {gid && sid ? (
        <Box sx={{ ml: '20px', display: 'flex', alignItems: 'center', fontSize: 20 }}>
          <Typography variant='body2'>Class data</Typography>
          <AntSwitch
            defaultChecked
            inputProps={{ 'aria-label': 'ant design' }}
            onChange={switchHandler}
          />
          <Typography variant='body2'>All surveys data</Typography>
        </Box>
      ) : (
        <></>
      )}

      <Box>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <ReportFooter typofAlign='right' typofColor='#2b2b2b' />
      </Box>
    </Box>
  )
}

ChartReport.propTypes = {
  gid: PropTypes.string,
  sid: PropTypes.string,
  formAnswerId: PropTypes.string
}

export default ChartReport
