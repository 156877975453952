import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { REDUCER_STATE } from '@/constants/store'
import { getVersion } from '@/store/actions/version'
import { useError } from '@/libs/hooks'

const VersionUI = (props) => {
  const dispatch = useDispatch()

  // const { typoAlign, typoColor } = props

  const [versionInfo, setVersionInfo] = useState({
    name: '',
    version: '',
    description: ''
  })

  const version = useSelector(
    (state) => state[REDUCER_STATE.VERSION.NAME][REDUCER_STATE.VERSION.FIELDS.VERSION]
  )

  const isGettingVersion = useSelector(
    (state) => state[REDUCER_STATE.VERSION.NAME][REDUCER_STATE.VERSION.FIELDS.IS_GETTING_VERSION]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.VERSION.NAME][REDUCER_STATE.VERSION.FIELDS.ERROR]
  )

  const prevIsGetting = useRef(isGettingVersion)

  useError(error)

  useEffect(() => {
    if (prevIsGetting.current && !isGettingVersion && !error) {
      setVersionInfo({
        ...versionInfo,
        name: _.upperCase(version.name),
        version: version.version,
        description: version.description
      })
    }
    prevIsGetting.current = isGettingVersion
  }, [isGettingVersion, error, version, versionInfo])

  useEffect(() => {
    dispatch(getVersion())
  }, [dispatch])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Typography
            align={props.typoAlign}
            variant='body2'
            color={props.typoColor}
            sx={{ fontSize: '10px', pt: 1 }}
          >
            {versionInfo.name} {versionInfo.version}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

VersionUI.propTypes = {
  typoAlign: PropTypes.string,
  typoColor: PropTypes.string
}

export default VersionUI
