import { createSlice } from '@reduxjs/toolkit'
import { REDUCER_STATE } from '@/constants/store'
import {
  createGroup,
  getGroups,
  getGroupByid,
  updateGroupByid,
  enableReportById,
  getReportAccess
} from '@/store/actions/group'

const {
  GROUP: { FIELDS }
} = REDUCER_STATE

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.GROUP]: null,
  [FIELDS.GROUPS]: null,
  [FIELDS.GROUP_BY_ID]: null,
  [FIELDS.GROUP_ANSWER]: null,
  [FIELDS.GROUP_ENABLED]: null,
  [FIELDS.REPORT_ACCESS]: null,
  [FIELDS.IS_GETTING_GROUPS]: false,
  [FIELDS.IS_GETTING_GROUP]: false,
  [FIELDS.IS_UPDATING_GROUP]: false,
  [FIELDS.IS_CREATING_NEW_GROUP]: false,
  [FIELDS.IS_ENABLING_REPORT]: false,
  [FIELDS.IS_GETTING_REPORT_ACCESS]: false
}

const reducers = {}

const extraReducers = {
  [createGroup.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING_NEW_GROUP]: true,
    [FIELDS.GROUP_ANSWER]: null
  }),
  [createGroup.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_NEW_GROUP]: false,
    [FIELDS.GROUP]: action.payload
  }),
  [createGroup.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_NEW_GROUP]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getGroups.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_GROUPS]: true,
    [FIELDS.GROUP_ANSWER]: null
  }),
  [getGroups.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_GROUPS]: false,
    [FIELDS.GROUPS]: action.payload
  }),
  [getGroups.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_GROUPS]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getGroupByid.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_GROUP]: true,
    [FIELDS.GROUP_ANSWER]: null,
    [FIELDS.GROUP_BY_ID]: null
  }),
  [getGroupByid.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_GROUP]: false,
    [FIELDS.GROUP_BY_ID]: action.payload
  }),
  [getGroupByid.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_GROUP]: false,
    [FIELDS.ERROR]: action.error
  }),
  [updateGroupByid.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_UPDATING_GROUP]: true
  }),
  [updateGroupByid.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_GROUP]: false,
    [FIELDS.GROUP_ANSWER]: action.payload
  }),
  [updateGroupByid.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_UPDATING_GROUP]: false,
    [FIELDS.ERROR]: action.error
  }),
  [enableReportById.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_ENABLING_REPORT]: true
  }),
  [enableReportById.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_ENABLING_REPORT]: false,
    [FIELDS.GROUP_ENABLED]: action.payload
  }),
  [enableReportById.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_ENABLING_REPORT]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getReportAccess.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_REPORT_ACCESS]: true
  }),
  [getReportAccess.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_REPORT_ACCESS]: false,
    [FIELDS.REPORT_ACCESS]: action.payload
  }),
  [getReportAccess.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_REPORT_ACCESS]: false,
    [FIELDS.ERROR]: action.error
  })
}

const slice = createSlice({
  name: REDUCER_STATE.GROUP.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
