import { createSlice } from '@reduxjs/toolkit'
import { REDUCER_STATE } from '@/constants/store'
import {
  login,
  logout,
  registerUser,
  emailResetPassword,
  resetPassword,
  getSessionStatus
} from '@/store/actions/auth'

const {
  AUTH: { FIELDS }
} = REDUCER_STATE

const initialState = {
  [FIELDS.ERROR]: null,
  [FIELDS.AUTH]: JSON.parse(sessionStorage.getItem('currentUser')),
  [FIELDS.AUTH_ANSWER]: null,
  [FIELDS.IS_GETTING_LOGIN]: false,
  [FIELDS.IS_LOGGED_IN]:
    JSON.parse(sessionStorage.getItem('isLoggedIn')) != null
      ? JSON.parse(sessionStorage.getItem('isLoggedIn'))
      : false,
  [FIELDS.IS_GETTING_LOGOUT]: false,
  [FIELDS.IS_LOGGED_OUT]: false,
  [FIELDS.IS_GETTING_SESSION]: false,
  [FIELDS.IS_CREATING_NEW_USER]: false,
  [FIELDS.IS_EMAIL_RESETTING_PASSWORD]: false,
  [FIELDS.IS_RESETTING_PASSWORD]: false
}

const reducers = {}

const extraReducers = {
  [login.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_LOGIN]: true,
    [FIELDS.AUTH_ANSWER]: null
  }),
  [login.fulfilled]: (state, action) => {
    sessionStorage.setItem('currentUser', JSON.stringify(action.payload))
    sessionStorage.setItem('isLoggedIn', true)
    return {
      ...state,
      [FIELDS.IS_GETTING_LOGIN]: false,
      [FIELDS.IS_LOGGED_IN]: true,
      [FIELDS.AUTH]: action.payload
    }
  },
  [login.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_LOGIN]: false,
    [FIELDS.ERROR]: action.error
  }),
  [logout.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_LOGOUT]: true,
    [FIELDS.IS_LOGGED_OUT]: false
  }),
  [logout.fulfilled]: (state, action) => {
    sessionStorage.setItem('currentUser', null)
    sessionStorage.setItem('isLoggedIn', false)
    return {
      ...state,
      [FIELDS.IS_GETTING_LOGOUT]: false,
      [FIELDS.IS_LOGGED_IN]: false,
      [FIELDS.IS_LOGGED_OUT]: true,
      [FIELDS.AUTH_ANSWER]: action.payload,
      [FIELDS.AUTH]: null
    }
  },
  [logout.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_LOGIN]: false,
    [FIELDS.ERROR]: action.error
  }),
  [registerUser.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_CREATING_NEW_USER]: true
  }),
  [registerUser.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_NEW_USER]: false,
    [FIELDS.AUTH_ANSWER]: action.payload
  }),
  [registerUser.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_CREATING_NEW_USER]: false,
    [FIELDS.ERROR]: action.error
  }),
  [emailResetPassword.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_EMAIL_RESETTING_PASSWORD]: true
  }),
  [emailResetPassword.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_EMAIL_RESETTING_PASSWORD]: false,
    [FIELDS.AUTH_ANSWER]: action.payload
  }),
  [emailResetPassword.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_EMAIL_RESETTING_PASSWORD]: false,
    [FIELDS.ERROR]: action.error
  }),
  [resetPassword.pending]: (state) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_RESETTING_PASSWORD]: true
  }),
  [resetPassword.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_RESETTING_PASSWORD]: false,
    [FIELDS.AUTH_ANSWER]: action.payload
  }),
  [resetPassword.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_RESETTING_PASSWORD]: false,
    [FIELDS.ERROR]: action.error
  }),
  [getSessionStatus.pending]: (state, action) => ({
    ...state,
    [FIELDS.ERROR]: null,
    [FIELDS.IS_GETTING_SESSION]: true,
    [FIELDS.AUTH_ANSWER]: null
  }),
  [getSessionStatus.fulfilled]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_SESSION]: false,
    [FIELDS.AUTH_ANSWER]: action.payload
  }),
  [getSessionStatus.rejected]: (state, action) => ({
    ...state,
    [FIELDS.IS_GETTING_SESSION]: false,
    [FIELDS.ERROR]: action.error
  })
}

const slice = createSlice({
  name: REDUCER_STATE.AUTH.NAME,
  initialState,
  reducers,
  extraReducers
})

export default slice
