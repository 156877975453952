import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { REDUCER_STATE } from '@/constants/store'
import { useError } from '@/libs/hooks'
import { getGroups, enableReportById } from '@/store/actions/group'
import PropTypes from 'prop-types'
import download from 'downloadjs'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { TablePagination } from '@material-ui/core'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import DownloadIcon from '@mui/icons-material/Download'
import MaterialTable from '@material-table/core'
import VersionUI from '@/components/versionUI'

import { getForm } from '@/store/actions/form'
import { REPORTS_ROUTES, TEACHER_DASHBOARD } from '@/constants/routes'
import CopyRight from '@/components/copyRight'
import TableIcons from '@/components/tableIcons'
import Layout from '@/components/layout'

const TeacherDashboard = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [tableInfo, setTableInfo] = useState([])

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const groups = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.GROUPS]
  )

  const auth = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.AUTH]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const isGettingGroups = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.IS_GETTING_GROUPS]
  )

  const isEnablingReport = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.IS_ENABLING_REPORT]
  )

  const prevIsGetting = useRef(isGettingGroups)

  const currentDate = new Date()

  const columns = [
    { title: 'Group Name', field: 'name', editable: 'never' },
    { title: 'Members', field: 'totalMembers', editable: 'never' },
    { title: 'Completed Surveys', field: 'totalRespondents', editable: 'never' }
  ]

  useError(error)

  useEffect(() => {
    dispatch(getGroups())
  }, [dispatch])

  useEffect(() => {
    dispatch(getForm())
  }, [dispatch])

  useEffect(() => {
    if (prevIsGetting.current && !isGettingGroups && !error && !isEnablingReport) {
      const newDataForTable = groups.map(
        ({ _id, name, totalMembers, totalRespondents, closeDate, reportAccess }) => ({
          _id,
          name,
          totalMembers,
          totalRespondents,
          closeDate,
          reportAccess
        })
      )
      setTableInfo(newDataForTable)
    }
    prevIsGetting.current = isGettingGroups
  }, [isGettingGroups, error, groups, isEnablingReport])

  const enableReport = (id, reportAccess) => {
    return new Promise((resolve, reject) => {
      dispatch(enableReportById({ id, reportAccess }))
      resolve()
    })
  }

  const onClickNewClass = () => {
    history.push(TEACHER_DASHBOARD.NEW_GROUP)
  }

  const onClickCsv = async () => {
    const fieldId = form._id

    let url = `/api/forms/${fieldId}/csv`
    return fetch(url, {
      method: 'GET'
    })
      .then(function (resp) {
        return resp.blob()
      })
      .then(function (blob) {
        download(blob, 'Slim-data')
      })
  }

  return (
    <>
      {auth.role === 'Admin' ? (
        <Container maxWidth='md'>
          <Layout title={'SLIM Admin'} />
          <Container sx={{ height: '100%' }} maxWidth='xs'>
            <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
              <Box sx={{ p: 0.5, pt: 3, margin: (theme) => theme.spacing(2), mt: 10 }}>
                <Grid container spacing={1} sx={{ width: '100%' }}>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      size='large'
                      variant='contained'
                      color='primary'
                      endIcon={<DownloadIcon />}
                      onClick={onClickCsv}
                      sx={{ mt: 1 }}
                    >
                      Download SLIM Data
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: '15px' }}>
                  <CopyRight />
                </Grid>
                <Grid item xs={12} sx={{ mb: '15px' }}>
                  <VersionUI typoAlign='center' />
                </Grid>
              </Box>
            </Grid>
          </Container>
        </Container>
      ) : (
        <Container sx={{ height: '100%' }} maxWidth='lg'>
          <Layout title={'Teacher Dashboard'} />
          <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
            <Box sx={{ p: 0.1, ml: '5px', pt: 1 }} style={{ overflow: 'auto' }}>
              <Grid container spacing={1} sx={{ width: '100%' }}>
                <Grid item xs={12}>
                  <MaterialTable
                    components={{
                      Pagination: (props) => (
                        <Box sx={{ textAlign: '-webkit-right' }}>
                          <TablePagination {...props} />
                        </Box>
                      )
                    }}
                    icons={TableIcons}
                    columns={columns}
                    data={tableInfo}
                    options={{
                      sorting: false,
                      search: false,
                      addRowPosition: 'last',
                      actionsColumnIndex: -1,
                      rowStyle: (rowData) => ({
                        backgroundColor: '#fcfcfc',
                        color: currentDate.getTime() > rowData.closeDate ? '#f44336' : '#4ac639'
                      }),
                      headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                      }
                    }}
                    title={
                      <Box
                        sx={{
                          fontSize: '22px',
                          fontWeight: 500
                        }}
                      >
                        {'Groups List'}
                      </Box>
                    }
                    actions={[
                      {
                        icon: TableIcons.InsertChartOutlinedIcon,
                        tooltip: 'Group Report',
                        onClick: (event, rowData) => {
                          const url = generatePath(REPORTS_ROUTES.TEACHER_REPORTS_DASHBOARD, {
                            gid: rowData._id
                          })
                          history.push(url)
                        }
                      },
                      {
                        icon: TableIcons.Edit,
                        tooltip: 'Modify Group',
                        onClick: (event, rowData) => {
                          const url = generatePath(TEACHER_DASHBOARD.EDIT_GROUP, {
                            id: rowData._id
                          })
                          history.push(url)
                        }
                      },
                      (rowData) => {
                        return {
                          icon: () => (
                            <CircleTwoToneIcon
                              color={
                                tableInfo[rowData.tableData.id].reportAccess ? 'success' : 'error'
                              }
                            />
                          ),
                          onClick: () => {
                            const id = rowData._id
                            const reportAccess = !rowData.reportAccess
                            enableReport(id, reportAccess).then(() => {
                              dispatch(getGroups())
                            })
                          },
                          tooltip: 'Enable/Disable Report for students'
                        }
                      }
                    ]}
                  ></MaterialTable>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size='large'
                    variant='contained'
                    color='primary'
                    onClick={onClickNewClass}
                    sx={{ mt: 1 }}
                  >
                    New Group
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mb: '15px' }}>
                <CopyRight />
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <VersionUI typoAlign='right' typoColor='#F4F4F4' />
          </Grid>
        </Container>
      )}
    </>
  )
}

TeacherDashboard.propTypes = {
  data: PropTypes.object
}

export default TeacherDashboard
