import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useError } from '@/libs/hooks'

import Box from '@mui/material/Box'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsHeatmap from 'highcharts/modules/heatmap'
import HighchartsMore from 'highcharts/highcharts-more'

import { SCALES } from '@/constants/reports'
import { REDUCER_STATE } from '@/constants/store'
import { getForm, getFormAnswerGid, getFormAnswerGidStudentsName } from '@/store/actions/form'
import { computeHeatMap } from '@/libs/utils'

HighchartsMore(Highcharts)
HighchartsHeatmap(Highcharts)
require('highcharts/modules/exporting')(Highcharts)

const getPointCategoryName = (point, dimension) => {
  const series = point.series,
    isY = dimension === 'y',
    axis = series[isY ? 'yAxis' : 'xAxis']
  return axis.categories[point[isY ? 'y' : 'x']]
}

const HeatMapTeacherReport = (props) => {
  const dispatch = useDispatch()
  const { gid } = props

  const [heatMapData, setHeatMapData] = useState([])

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const formAnswerGid = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_GID]
  )

  const formAnswerGidStudentNames = useSelector(
    (state) =>
      state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_GID_STUDENT_NAME]
  )

  const isGettingFormAnswerGidStudentNames = useSelector(
    (state) =>
      state[REDUCER_STATE.FORM.NAME][
        REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM_ANSWER_GID_STUDENT_NAME
      ]
  )

  const heatMapOptions = {
    chart: {
      type: 'heatmap',
      marginTop: 10,
      marginBottom: 80,
      plotBorderWidth: 1
    },

    title: {
      text: null
    },

    xAxis: {
      categories: [
        SCALES.SURFACE_ACQUIRING.SUBSCALES.OUTLINE_NAME,
        SCALES.SURFACE_ACQUIRING.SUBSCALES.ORGANIZE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DELIBERATE_PRACTICE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.PRACTICE_TESTING_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.REHEARSAL_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.FEEDBACK_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DISTRIBUTED_PRACTICE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.MNEMONICS_NAME,
        SCALES.DEEP_ACQUIRING.SUBSCALES.RELATING_IDEAS_NAME,
        SCALES.DEEP_ACQUIRING.SUBSCALES.SEEKING_CLARITY_NAME,
        SCALES.DEEP_CONSOLIDATION.SUBSCALES.SELF_VERBALIZATION_NAME,
        SCALES.TRANSFER.SUBSCALES.SIMILARITIES_DIFFERENCES_NAME
      ]
    },

    yAxis: {
      categories: formAnswerGidStudentNames,
      title: null,
      reversed: true
    },

    accessibility: {
      point: {
        descriptionFormatter: function (point) {
          var ix = point.index + 1,
            xName = getPointCategoryName(point, 'x'),
            yName = getPointCategoryName(point, 'y'),
            val = point.value
          return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.'
        }
      }
    },

    colorAxis: {
      min: 1,
      max: 5,
      endOnTick: false,
      startOnTick: false,
      tickInterval: 1,
      stops: [
        [0.25, '#FFE5E6'], //red
        [0.5, '#FFFFDD'], //yellow
        [0.75, '#E5F6EC'] //green
      ]
    },

    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280
    },
    // tooltip: {
    //   formatter: function () {
    //     return (
    //       '<b>' +
    //       getPointCategoryName(this.point, 'x') +
    //       '</b> sold <br><b>' +
    //       this.point.value +
    //       '</b> items on <br><b>' +
    //       getPointCategoryName(this.point, 'y') +
    //       '</b>'
    //     )
    //   }
    // },
    tooltip: false,
    series: [
      {
        name: 'Sales per employee',
        borderWidth: 1,
        data: heatMapData,
        dataLabels: {
          enabled: true,
          color: '#000000'
        }
      }
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0)
                }
              }
            }
          }
        }
      ]
    }
  }

  useError(error)

  useEffect(() => {
    if (!form && !error) {
      dispatch(getForm())
      if (!formAnswerGid && gid) {
        dispatch(getFormAnswerGid({ formId: form._id, gid: gid }))
      }
    }
  }, [dispatch, error, form, formAnswerGid, gid])

  useEffect(() => {
    if (!formAnswerGidStudentNames && !isGettingFormAnswerGidStudentNames && !error) {
      if (gid) {
        dispatch(getFormAnswerGidStudentsName({ formId: form._id, gid: gid }))
      }
    }
  }, [
    dispatch,
    error,
    form._id,
    formAnswerGidStudentNames,
    gid,
    isGettingFormAnswerGidStudentNames
  ])

  useEffect(() => {
    if (formAnswerGid && !isGettingFormAnswerGidStudentNames && !error) {
      setHeatMapData(computeHeatMap(formAnswerGid.doc))
    }
  }, [error, formAnswerGid, isGettingFormAnswerGidStudentNames])

  return (
    <Box>
      <HighchartsReact highcharts={Highcharts} options={heatMapOptions} />
    </Box>
  )
}

HeatMapTeacherReport.propTypes = {
  gid: PropTypes.string
}

export default HeatMapTeacherReport
