import BaseApi from './base'
/**
 * This class wraps multiple version api requests to the server.
 */

class VersionApi extends BaseApi {
  /**
   * Retrive current version of application
   * @return {Promise<*>}
   */
  async getVersion() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.GET_VERSION)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
}

export default VersionApi
