import { React } from 'react'
import { useParams } from 'react-router-dom'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import Layout from '@/components/layout'
import TeacherReportContainer from '@/components/teacherReportContainer'
import VersionUI from '@/components/versionUI'

const TeacherReportsDashboardPage = () => {
  const routeParams = useParams()
  const { gid } = routeParams

  return (
    <>
      <Container maxWidth='xl' /* sx={{ height: '100' }} */>
        <Layout title={'Teacher Reports'} />
        <Paper elevation={15} style={{ borderRadius: '8px' }}>
          <Grid container>
            <TeacherReportContainer gid={gid} />
          </Grid>
        </Paper>
        <Grid item xs={12}>
          <VersionUI typoAlign='right' typoColor='#F4F4F4' />
        </Grid>
      </Container>
    </>
  )
}
export default TeacherReportsDashboardPage
