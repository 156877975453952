import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'

import { REPORTS_FOOTER } from '@/constants/reports'

const ReportFooter = (props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Typography
            align={props.typofAlign}
            variant='body2'
            color={props.typofColor}
            sx={{ fontSize: '10px', pt: 1 }}
          >
            {REPORTS_FOOTER.FOOTER}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

ReportFooter.propTypes = {
  typofAlign: PropTypes.string,
  typofColor: PropTypes.string
}

export default ReportFooter
