/* eslint-disable indent */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { authApi } from '@/services/api'
import {
  LOGIN,
  LOGOUT,
  REGISTER_USER,
  EMAIL_RESET_PASSWORD,
  RESET_PASSWORD,
  GET_SESSION_STATUS
} from '@/store/types/auth'

const login = createAsyncThunk(LOGIN, async (payload) => {
  return await authApi.login(payload)
})

const logout = createAsyncThunk(LOGOUT, async () => {
  return await authApi.logout()
})

const getSessionStatus = createAsyncThunk(GET_SESSION_STATUS, async () => {
  return await authApi.getSessionStatus()
})

const registerUser = createAsyncThunk(REGISTER_USER, async (payload) => {
  return await authApi.registerUser(payload)
})

const emailResetPassword = createAsyncThunk(EMAIL_RESET_PASSWORD, async (payload) => {
  return await authApi.emailResetPassword(payload)
})

const resetPassword = createAsyncThunk(RESET_PASSWORD, async (payload) => {
  return await authApi.resetPassword(payload)
})

export { login, logout, registerUser, getSessionStatus, emailResetPassword, resetPassword }
