import React from 'react'
import { useHistory } from 'react-router-dom'

import { ROOT_ROUTES } from '@/constants/routes'
import logo from '@/assets/img/logo.png'
import PropTypes from 'prop-types'

const PageLogo = (props) => {
  const history = useHistory()
  const { gid, sid, formAnswerId } = props

  const onClickImage = () => {
    if (!sid && !gid && !formAnswerId) {
      history.push(ROOT_ROUTES.LANDING_PAGE)
    }
  }
  return (
    <div>
      <img src={logo} alt='logo' width='170' onClick={onClickImage} style={{ cursor: 'pointer' }} />
    </div>
  )
}

PageLogo.propTypes = {
  gid: PropTypes.string,
  sid: PropTypes.string,
  formAnswerId: PropTypes.string
}
export default PageLogo
