import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useError } from '@/libs/hooks'
import PropTypes from 'prop-types'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DownloadIcon from '@mui/icons-material/Download'
import IconButton from '@mui/material/IconButton'

import BackupTableIcon from '@mui/icons-material/BackupTable'
import SpeedIcon from '@mui/icons-material/Speed'
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'

import TableReport from '@/components/reports/tableReport'
import DialReport from '@/components/reports/dialReport'
import ChartReport from '@/components/reports/chartReport'
import RecomReport from '@/components/reports/recomReport'

import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

import { REDUCER_STATE } from '@/constants/store'
import { getForm, getFormAnswerIndividual, getFormAnswerSidGid } from '@/store/actions/form'

const ReportContainer = (props) => {
  const dispatch = useDispatch()
  const { gid, sid, formAnswerId } = props

  const [reportSelection, setReportSelection] = useState({
    reportName: 'home'
  })
  const [anchorEl, setAnchorEl] = useState()
  const [enableDownloadButton, setEnableDownloadButton] = useState(false)

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const isGettingForm = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const prevIsGettingForm = useRef(isGettingForm)

  const openTopBar = Boolean(anchorEl)

  const handleClickTopBar = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseTopBar = () => {
    setAnchorEl(null)
  }

  const renderReports = () => {
    let component
    switch (reportSelection.reportName) {
      case 'Table':
        component = <TableReport gid={gid} sid={sid} formAnswerId={formAnswerId} />
        break
      case 'Dial':
        component = <DialReport gid={gid} sid={sid} formAnswerId={formAnswerId} />
        break
      case 'Boxplot':
        component = <ChartReport gid={gid} sid={sid} formAnswerId={formAnswerId} />
        break
      case 'recomrep':
        component = <RecomReport />
        break
      default:
        component = (
          <Box>
            <Typography variant='h5' sx={{ marginTop: '20px', marginLeft: '20px' }}>
              Instructions
            </Typography>
            <Box
              sx={{
                margin: '20px',
                padding: '20px',
                WebkitBoxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)',
                boxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)'
              }}
            >
              <Typography variant='h6' sx={{ marginLeft: '20px' }}>
                You can choose the reports you wish, and Recommendations reports to enhance the
                strategies.
              </Typography>
            </Box>
          </Box>
        )
    }
    return component
  }

  const printComponent = async () => {
    const component = document.getElementById('divToPrint')
    html2canvas(component).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg')
      const pdf = new jsPDF('p', 'pt', 'a4', false)
      const imgProperties = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, false)
      pdf.save(reportSelection.reportName + ' Report.pdf')
    })
  }

  useError(error)

  useEffect(() => {
    handleCloseTopBar()
  }, [setReportSelection])

  useEffect(() => {
    dispatch(getForm())
  }, [dispatch])

  useEffect(() => {
    if (form && !isGettingForm && prevIsGettingForm.current && !error) {
      if (formAnswerId) {
        dispatch(getFormAnswerIndividual({ formId: form._id, formAnswerId: formAnswerId }))
      }
      if (gid && sid) {
        dispatch(getFormAnswerSidGid({ formId: form._id, gid: gid, sid: sid }))
      }
    }
    prevIsGettingForm.current = isGettingForm
  }, [dispatch, error, form, formAnswerId, gid, isGettingForm, sid])

  return (
    <Box sx={{ width: '100%', margin: (theme) => theme.spacing(1) }} style={{ overflow: 'auto' }}>
      {/* Report Side Bar */}
      {/* <ReportTopBar /> */}
      <Box>
        <Box
          sx={{
            padding: '15px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#f2f8ff'
          }}
        >
          <Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <IconButton onClick={handleClickTopBar}>
                <MenuIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={openTopBar}
                onClose={handleCloseTopBar}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                <MenuItem
                  onClick={() => {
                    setReportSelection({
                      reportName: 'Table'
                    })
                    handleCloseTopBar()
                    setEnableDownloadButton(true)
                  }}
                >
                  Table
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setReportSelection({
                      reportName: 'Dial'
                    })
                    handleCloseTopBar()
                    setEnableDownloadButton(true)
                  }}
                >
                  Dial
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setReportSelection({
                      reportName: 'Boxplot'
                    })
                    handleCloseTopBar()
                    setEnableDownloadButton(true)
                  }}
                >
                  Box Plot
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setReportSelection({
                      reportName: 'recomrep'
                    })
                    handleCloseTopBar()
                    setEnableDownloadButton(false)
                  }}
                >
                  Recommendations
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Box>
            {enableDownloadButton ? (
              <Box sx={{ position: 'relative' }}>
                <Button
                  variant='contained'
                  size='small'
                  sx={{ marginRight: '10px' }}
                  startIcon={<DownloadIcon />}
                  onClick={printComponent}
                >
                  Download Report
                </Button>
              </Box>
            ) : (
              <Box sx={{ position: 'relative' }}></Box>
            )}
          </Box>
        </Box>
      </Box>
      {/* Report Side Bar */}
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            flex: 1,
            position: 'sticky',
            top: '50px',
            backgroundColor: '#f2f8ff',
            display: { xs: 'none', sm: 'flex' }
          }}
        >
          <Box className='sidebarWrapper'>
            <Box className='sidebarMenu'>
              <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: '#f2f8ff' }}
                component='nav'
                aria-labelledby='nested-list-subheader'
              >
                <ListItemButton
                  onClick={() => {
                    setReportSelection({
                      reportName: 'Table'
                    })
                    setEnableDownloadButton(true)
                  }}
                >
                  <ListItemIcon>
                    <BackupTableIcon />
                  </ListItemIcon>
                  <ListItemText primary='Table' />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    setReportSelection({
                      reportName: 'Dial'
                    })
                    setEnableDownloadButton(true)
                  }}
                >
                  <ListItemIcon>
                    <SpeedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Dial' />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    setReportSelection({
                      reportName: 'Boxplot'
                    })
                    setEnableDownloadButton(true)
                  }}
                >
                  <ListItemIcon>
                    <CandlestickChartOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Box Plot' />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    setReportSelection({
                      reportName: 'recomrep'
                    })
                    setEnableDownloadButton(false)
                  }}
                >
                  <ListItemIcon>
                    <ArticleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Recommendations' />
                </ListItemButton>
              </List>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 4,
            overflow: 'auto'
          }}
          id='divToPrint'
        >
          {renderReports()}
        </Box>
      </Box>
    </Box>
  )
}

ReportContainer.propTypes = {
  gid: PropTypes.string,
  sid: PropTypes.string,
  formAnswerId: PropTypes.string
}

export default ReportContainer
