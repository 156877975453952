import { createAsyncThunk } from '@reduxjs/toolkit'
import { groupApi } from '@/services/api'
import {
  CREATE_GROUP,
  GET_GROUPS,
  GET_GROUPS_ALL,
  GET_GROUP_BY_ID,
  UPDATE_GROUP_BY_ID,
  DELETE_GROUP_BY_ID,
  ENABLE_REPORT_BY_ID,
  GET_REPORT_ACCESS
} from '@/store/types/group'

const createGroup = createAsyncThunk(CREATE_GROUP, async (payload) => {
  return await groupApi.createGroup(payload)
})

const getGroups = createAsyncThunk(GET_GROUPS, async () => {
  return await groupApi.getGroups()
})

const getGroupsAll = createAsyncThunk(GET_GROUPS_ALL, async (payload) => {
  //TODO
})

const getGroupByid = createAsyncThunk(GET_GROUP_BY_ID, async (payload) => {
  return await groupApi.getGroupByid(payload)
})

const updateGroupByid = createAsyncThunk(UPDATE_GROUP_BY_ID, async (payload) => {
  return await groupApi.updateGroupByid(payload)
})

const enableReportById = createAsyncThunk(ENABLE_REPORT_BY_ID, async (payload) => {
  return await groupApi.enableReportById(payload)
})

const deleteGroupByid = createAsyncThunk(DELETE_GROUP_BY_ID, async (payload) => {
  //TODO
})

const getReportAccess = createAsyncThunk(GET_REPORT_ACCESS, async (payload) => {
  return await groupApi.getReportAccess(payload)
})

export {
  createGroup,
  getGroups,
  getGroupsAll,
  getGroupByid,
  updateGroupByid,
  deleteGroupByid,
  enableReportById,
  getReportAccess
}
