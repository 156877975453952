import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useError } from '@/libs/hooks'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LogoutIcon from '@mui/icons-material/Logout'

import PageLogo from '@/components/pageLogo'
import { logout } from '@/store/actions/auth'
import { REDUCER_STATE } from '@/constants/store'
import { TEACHER_ACCESS } from '@/constants/routes'
import { useQuery } from '@/libs/utils'

const Layout = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const query = useQuery()
  const sid = query.get('sid')
  const gid = query.get('gid')
  const formAnswerId = query.get('formAnswerId')

  const { title, isIndividual } = props

  const isGettingLogout = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_GETTING_LOGOUT]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.ERROR]
  )

  const isLoggedOut = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_LOGGED_OUT]
  )

  const prevIsLoggingOut = useRef(isGettingLogout)

  useError(error)

  useEffect(() => {
    if (prevIsLoggingOut.current && !isGettingLogout && !error && isLoggedOut) {
      history.push(TEACHER_ACCESS.LOGIN)
    }
  }, [error, history, isGettingLogout, isLoggedOut])

  const onClickLogout = () => {
    dispatch(logout())
  }

  return (
    <Box sx={{ mt: '1em', mb: '1em' }}>
      <Grid container>
        <Grid item xs={4} justifyContent='flex-start'>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PageLogo gid={gid} sid={sid} formAnswerId={formAnswerId} />
          </Box>
        </Grid>
        <Grid item xs={4} container justifyContent='center'>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant='h4'
              align='center'
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            >
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} container justifyContent='flex-end'>
          {!isIndividual ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant='contained'
                color='inherit'
                onClick={onClickLogout}
                startIcon={<LogoutIcon />}
              >
                Logout
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }} />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  isIndividual: PropTypes.bool
}

export default Layout
