import { ttl } from '@/libs/utils'

const ERROR_MESSAGES = {
  EMPTY_FIELDS: 'Please complete all fields before continue.',
  EMPTY_FIELD_ERROR: ttl`${'paramName'} is required.`,
  INVALID_EMAIL: 'Please enter a valid email.',
  INVALID_PASSWORD:
    'Password must be at least 8 characters, contain lower case, upper case, number and a symbol.',
  INVALID_MATCH_PASSWORD: 'Password does not match confirmation.',
  CLOSEDATE_GREATER_THAN_OPENDATE: 'Close Date must be greater than Open date.'
}

export { ERROR_MESSAGES }
