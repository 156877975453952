import { useLocation } from 'react-router-dom'
import React from 'react'
import boxplot from '@sgratzl/boxplots'

const pageArray = [
  'PAGE_1',
  'PAGE_2',
  'PAGE_3',
  'PAGE_4',
  'PAGE_5',
  'PAGE_6',
  'PAGE_7',
  'PAGE_8',
  'PAGE_9',
  'PAGE_10',
  'PAGE_11',
  'PAGE_12'
]

const ttl = (strings, ...keys) => {
  return function (collection) {
    let result = [strings[0]]
    collection = collection || {}
    keys.forEach(function (key, i) {
      result.push(collection[key], strings[i + 1])
    })
    return result.join('')
  }
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

// Function that calculate the average of each page
// returning an array of number of pages lenght
const abstractAverageAnswers = (answers, hasDoc) => {
  const averageArray = []
  if (answers) {
    for (let i = 0; i < pageArray.length; i++) {
      const tmpAnswers = hasDoc ? answers.answer[pageArray[i]] : answers.doc.answer[pageArray[i]]
      let questions = Object.keys(tmpAnswers)
      let average = 0
      for (let j = 0; j < questions.length; j++) {
        average += tmpAnswers[questions[j]].value
      }
      //console.log(average)
      averageArray.push(average / questions.length)
      //console.log(averageArray)
    }
  } else {
    return null
  }
  return averageArray
}

// Function that iterate formAnswers and return an array of array with answers
// const abstractAnswers = (answers) => {
//   const questionArray = []
//   for (let i = 0; i < pageArray.length; i++) {
//     let questions = Object.keys(answers.answer[pageArray[i]])
//     let tempPageArray = []
//     for (let j = 0; j < questions.length; j++) {
//       tempPageArray.push(answers.answer[pageArray[i]][questions[j]].value)
//     }
//     questionArray.push(tempPageArray)
//   }
//   return questionArray
// }

const computeHeatMap = (formAnswerGid) => {
  const heatmapdata = []
  const averageAnsw = []
  for (let i = 0; i < formAnswerGid.length; i++) {
    //console.log(formAnswerGid)
    averageAnsw.push(abstractAverageAnswers(formAnswerGid[i], true))
    //console.log(averageAnsw)
  }
  for (let i = 0; i < pageArray.length; i++) {
    for (let j = 0; j < averageAnsw.length; j++) {
      const tempPageArray = []
      tempPageArray.push(i)
      tempPageArray.push(j)
      tempPageArray.push(parseFloat(averageAnsw[j][i].toFixed(1)))
      heatmapdata.push(tempPageArray)
    }
  }
  return heatmapdata
}

const computeBoxPlot = (avgAnswersObj) => {
  const questionsAvgArray = []
  if (avgAnswersObj) {
    for (let i = 0; i < pageArray.length; i++) {
      let tempPageArray = []
      tempPageArray.push(
        Math.round((boxplot(avgAnswersObj[pageArray[i]]).min + Number.EPSILON) * 100) / 100
      )
      tempPageArray.push(
        Math.round((boxplot(avgAnswersObj[pageArray[i]]).q1 + Number.EPSILON) * 100) / 100
      )
      tempPageArray.push(
        Math.round((boxplot(avgAnswersObj[pageArray[i]]).median + Number.EPSILON) * 100) / 100
      )
      tempPageArray.push(
        Math.round((boxplot(avgAnswersObj[pageArray[i]]).q3 + Number.EPSILON) * 100) / 100
      )
      tempPageArray.push(
        Math.round((boxplot(avgAnswersObj[pageArray[i]]).max + Number.EPSILON) * 100) / 100
      )
      questionsAvgArray.push(tempPageArray)
    }
  } else {
    return null
  }

  return questionsAvgArray
}

//Function that computes Stack bar, iterates formAnswersAvg and count each level
const computeStackBar = (avgAnswersObj) => {
  const percentages = []
  const twentyArray = []
  const fiftyArray = []
  const eightyArray = []
  for (let i = 0; i < pageArray.length; i++) {
    let twentyTemp = 0
    let fiftyTemp = 0
    let eightyTemp = 0
    for (let j = 0; j < avgAnswersObj[pageArray[i]].length; j++) {
      if (avgAnswersObj[pageArray[i]][j] >= 1 && avgAnswersObj[pageArray[i]][j] < 2.5) {
        twentyTemp += 1
      }
      if (avgAnswersObj[pageArray[i]][j] >= 2.5 && avgAnswersObj[pageArray[i]][j] < 3.5) {
        fiftyTemp += 1
      }
      if (avgAnswersObj[pageArray[i]][j] >= 3.5 && avgAnswersObj[pageArray[i]][j] <= 5) {
        eightyTemp += 1
      }
    }
    twentyArray.push(twentyTemp)
    fiftyArray.push(fiftyTemp)
    eightyArray.push(eightyTemp)
  }
  percentages.push(twentyArray)
  percentages.push(fiftyArray)
  percentages.push(eightyArray)
  return percentages
}

export { ttl, useQuery, abstractAverageAnswers, computeBoxPlot, computeStackBar, computeHeatMap }
