const DEFAULT_STATE = {
  DATA: 'data',
  IS_PENDING: 'isPending',
  ERROR: 'error'
}

const REDUCER_STATE = {
  ROUTER: {
    NAME: 'router'
  },
  FORM: {
    NAME: 'form',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      FORM: 'form',
      FORM_ANSWER: 'formAnswer',
      FORM_ANSWER_AVG: 'formAnswerAvg',
      FORM_ANSWER_SID_GID: 'formAnswerSidGid',
      FORM_ANSWER_GID: 'formAnswerGid',
      FORM_ANSWER_GID_STUDENT_NAME: 'formAnswerGidStudentsName',
      FORM_ANSWER_GID_AVG: 'formAnswerGidAvg',
      HAS_COMPLETED: 'hasCompleted',
      HAS_SURVEY_COMPLETED: 'hasSurveyCompleyed',
      IS_GETTING_SURVEY_COMPLETED: 'isGettingSurveyCompleted',
      IS_GETTING_FORM: 'isGettingForm',
      IS_GETTING_FORM_ANSWER: 'isGettingFormAnswer',
      IS_GETTING_FORM_ANSWER_AVG: 'isGettingFormAnswerAvg',
      IS_GETTING_FORM_ANSWER_SID_GID: 'isGettingFormAnswerSidGid',
      IS_GETTING_FORM_ANSWER_GID: 'isGettingFormAnswerGid',
      IS_GETTING_FORM_ANSWER_GID_STUDENT_NAME: 'isGettingFormAnswerGidStudentsName',
      IS_GETTING_FORM_ANSWER_GID_AVG: 'isGettingFormAnswerGidAvg',
      IS_UPDATING_FORM_ANSWER: 'isUpdatingFormAnswer',
      IS_CREATING_FORM_ANSWER: 'isCreatingFormAnswer'
    }
  },
  AUTH: {
    NAME: 'auth',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      AUTH: 'auth',
      AUTH_ANSWER: 'authAnswer',
      IS_GETTING_LOGIN: 'isGettingLogin',
      IS_LOGGED_IN: 'isLoggedIn',
      IS_GETTING_LOGOUT: 'isGettingLogout',
      IS_LOGGED_OUT: 'isLoggedOut',
      IS_GETTING_SESSION: 'isGettingSession',
      IS_CREATING_NEW_USER: 'isCreatingNewUser',
      IS_EMAIL_RESETTING_PASSWORD: 'isEmailResettingPassword',
      IS_RESETTING_PASSWORD: 'isResettingPassword'
    }
  },
  GROUP: {
    NAME: 'group',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      GROUP: 'group',
      GROUPS: 'groups',
      GROUP_BY_ID: 'groupById',
      GROUP_ANSWER: 'groupAnswer',
      GROUP_ENABLED: 'groupEnabled',
      REPORT_ACCESS: 'reportAccess',
      IS_GETTING_GROUPS: 'isGettingGroups',
      IS_GETTING_GROUP: 'isGettingGroup',
      IS_UPDATING_GROUP: 'isUpdatingGroup',
      IS_CREATING_NEW_GROUP: 'isCreatingNewGroup',
      IS_ENABLING_REPORT: 'isEnablingReport',
      IS_GETTING_REPORT_ACCESS: 'isGettingReportAccess'
    }
  },
  VERSION: {
    NAME: 'version',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      VERSION: 'version',
      IS_GETTING_VERSION: 'isGettingVersion'
    }
  }
}

export { REDUCER_STATE }
