import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { REDUCER_STATE } from '@/constants/store'
import { createGroup } from '@/store/actions/group'
import { useError } from '@/libs/hooks'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import Paper from '@mui/material/Paper'
import DateTimePicker from '@mui/lab/DateTimePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MaterialTable from '@material-table/core'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import VersionUI from '@/components/versionUI'
import { TablePagination } from '@material-ui/core'

import { TEACHER_ACCESS } from '@/constants/routes'
import { ERROR_MESSAGES } from '@/constants/errors'
import { REGEX } from '@/constants/regex'

import CopyRight from '@/components/copyRight'
import TableIcons from '@/components/tableIcons'
import Layout from '@/components/layout'

const NewGroupPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isCreatingNewGroup = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.IS_CREATING_NEW_GROUP]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const prevIsCreating = useRef(isCreatingNewGroup)

  const currentDate = new Date()
  const [formValues, setFormValues] = useState({
    name: '',
    openDate: currentDate.getTime(),
    closeDate: currentDate.getTime(),
    completionRate: 0,
    members: []
  })

  const [errorDates, seterrorDates] = useState({
    closeDateError: ''
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })
  }

  const [tableData, setTableData] = useState([])

  const columns = [
    {
      title: 'First Name',
      field: 'firstName',
      validate: (rowData) => {
        if (_.isEmpty(rowData.firstName)) {
          return ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'First Name' })
        }
        return true
      }
    },
    {
      title: 'Last Name',
      field: 'lastName',
      validate: (rowData) => {
        if (_.isEmpty(rowData.lastName)) {
          return ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'Last Name' })
        }
        return true
      }
    },
    {
      title: 'Email',
      field: 'email',
      validate: (rowData) => {
        if (_.isEmpty(rowData.email)) {
          return ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'Email' })
        } else {
          const isValid = REGEX.EMAIL_VALIDATOR.test(rowData.email)
          if (!isValid) {
            return ERROR_MESSAGES.INVALID_EMAIL
          }
        }
        return true
      }
    }
  ]

  const tabledataToPayload = () => {
    let outputData = tableData.map(({ tableData, ...rest }) => rest)
    setFormValues({
      ...formValues,
      members: [...outputData]
    })
  }

  useError(error)

  useEffect(() => {
    tabledataToPayload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData])

  useEffect(() => {
    if (prevIsCreating.current && !isCreatingNewGroup && !error) {
      // TODO => alert('Group Created')
      history.push(TEACHER_ACCESS.TEACHER_DASHBOARD)
    }
    prevIsCreating.current = isCreatingNewGroup
  }, [isCreatingNewGroup, error, history])

  const onClickSubmit = () => {
    if (formValues.closeDate <= formValues.openDate) {
      seterrorDates({
        closeDateError: ERROR_MESSAGES.CLOSEDATE_GREATER_THAN_OPENDATE
      })
    } else {
      dispatch(createGroup(formValues))
    }
  }

  const onClickBack = () => {
    history.push(TEACHER_ACCESS.TEACHER_DASHBOARD)
  }

  return (
    <Container sx={{ height: '100vh', mb: 10 }} maxWidth='lg'>
      <Layout title={'New Group'} />
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <Box sx={{ p: 1, margin: (theme) => theme.spacing(2) }} style={{ overflow: 'auto' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={2}>
                <FormLabel style={{ alignContent: 'end' }}> {'New Class:'}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  variant='standard'
                  name='name'
                  size='small'
                  InputLabelProps={{ shrink: false }}
                  placeholder={'Enter a class name'}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormLabel style={{ alignContent: 'end' }}> {'Open Date:'}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => (
                      <TextField size='small' variant='standard' {...params} />
                    )}
                    value={formValues.openDate}
                    onChange={(openDate) => {
                      let time = null
                      if (openDate !== null) {
                        time = openDate.getTime()
                      }
                      setFormValues({ ...formValues, openDate: time })
                    }}
                    minDate={currentDate}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormLabel style={{ alignContent: 'end' }}> {'Close Date:'}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => (
                      <TextField
                        size='small'
                        variant='standard'
                        {...params}
                        {...(errorDates.closeDateError && {
                          error: true,
                          helperText: errorDates.closeDateError
                        })}
                      />
                    )}
                    value={formValues.closeDate}
                    onChange={(closeDate) => {
                      seterrorDates({ closeDateError: '' })
                      let time = null
                      if (closeDate !== null) {
                        time = closeDate.getTime()
                      }
                      setFormValues({ ...formValues, closeDate: time })
                    }}
                    minDate={formValues.openDate}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormLabel style={{ alignContent: 'end' }}>{'Completion Rate Message:'}</FormLabel>
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant='standard'
                  name='completionRate'
                  size='small'
                  type='number'
                  min='0'
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>
                  }}
                  inputProps={{ min: 0, max: 100, step: 1 }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <MaterialTable
                  components={{
                    Pagination: (props) => (
                      <Box sx={{ textAlign: '-webkit-right' }}>
                        <TablePagination {...props} />
                      </Box>
                    )
                  }}
                  icons={TableIcons}
                  columns={columns}
                  data={tableData}
                  editable={{
                    onRowAdd: (newRow) =>
                      new Promise((resolve, reject) => {
                        setTableData([...tableData, newRow])
                        setTimeout(() => resolve(), 500)
                      }),
                    onRowUpdate: (newRow, oldRow) =>
                      new Promise((resolve, reject) => {
                        const updatedData = [...tableData]
                        updatedData[oldRow.tableData.id] = newRow
                        setTableData(updatedData)
                        setTimeout(() => resolve(), 500)
                      }),
                    onRowDelete: (selectedRow) =>
                      new Promise((resolve, reject) => {
                        const updatedData = [...tableData]
                        updatedData.splice(selectedRow.tableData.id, 1)
                        setTableData(updatedData)
                        setTimeout(() => resolve(), 1000)
                      })
                  }}
                  options={{
                    sorting: false,
                    search: false,
                    addRowPosition: 'last',
                    actionsColumnIndex: -1,
                    rowStyle: (rowData) => ({
                      backgroundColor: '#fcfcfc'
                    }),
                    headerStyle: {
                      backgroundColor: '#01579b',
                      color: '#FFF'
                    }
                  }}
                  title={
                    <Box
                      sx={{
                        fontSize: '22px',
                        fontWeight: 500
                      }}
                    >
                      {'Student List'}
                    </Box>
                  }
                ></MaterialTable>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  variant='outlined'
                  size='medium'
                  color='primary'
                  onClick={onClickBack}
                  startIcon={<ArrowBackIcon />}
                  sx={{ mt: 2 }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Button
                  fullWidth
                  variant='contained'
                  size='medium'
                  color='primary'
                  onClick={onClickSubmit}
                  sx={{ mt: 2 }}
                >
                  Create Group & Send Email
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CopyRight />
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <VersionUI typoAlign='right' typoColor='#F4F4F4' />
      </Grid>
    </Container>
  )
}
export default NewGroupPage
