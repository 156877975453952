import React from 'react'
import RootRoutes from '@/routes/root'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { store, history } from '@/store'
import { GlobalWrapper } from '@/styles'
import '@/styles/index.less'
import '@/styles/customSlider.scss'

import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles'

let theme = createTheme({
  palette: {
    primary: {
      light: '#5475e0',
      main: '#004AAD',
      dark: '#00247d',
      contrastText: '#F4F4F4'
    },
    typography: {
      fontFamily: ['Source-Sans-Pro'].join(',')
    }
  }
})
theme = responsiveFontSizes(theme)

const App = () => {
  return (
    <Provider store={store()}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <GlobalWrapper>
            <RootRoutes />
          </GlobalWrapper>
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  )
}

export default App
