import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useError } from '@/libs/hooks'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import HeatMapTeacherReport from '@/components/reports/heatMapTeacherReport'

import ReportFooter from '@/components/reportFooter'
import { SCALES } from '@/constants/reports'
import { REDUCER_STATE } from '@/constants/store'
import { getForm, getFormAnswerGid, getFormAnswerGidAvg } from '@/store/actions/form'
import { computeStackBar } from '@/libs/utils'

HighchartsMore(Highcharts)
require('highcharts/modules/exporting')(Highcharts)

const StackedTeacherReport = (props) => {
  const dispatch = useDispatch()
  const [groupName, setGroupName] = useState()
  const { gid } = props

  const [stackBarData, setStackBarData] = useState([])

  const form = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.GROUP.NAME][REDUCER_STATE.GROUP.FIELDS.ERROR]
  )

  const formAnswerGid = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_GID]
  )

  const formAnswerGidAvg = useSelector(
    (state) => state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.FORM_ANSWER_GID_AVG]
  )

  const isGettingFormAnswerGidAvg = useSelector(
    (state) =>
      state[REDUCER_STATE.FORM.NAME][REDUCER_STATE.FORM.FIELDS.IS_GETTING_FORM_ANSWER_GID_AVG]
  )

  const stackedChartptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: null
    },
    xAxis: {
      categories: [
        SCALES.SURFACE_ACQUIRING.SUBSCALES.OUTLINE_NAME,
        SCALES.SURFACE_ACQUIRING.SUBSCALES.ORGANIZE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DELIBERATE_PRACTICE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.PRACTICE_TESTING_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.REHEARSAL_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.FEEDBACK_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.DISTRIBUTED_PRACTICE_NAME,
        SCALES.SURFACE_CONSOLIDATION.SUBSCALES.MNEMONICS_NAME,
        SCALES.DEEP_ACQUIRING.SUBSCALES.RELATING_IDEAS_NAME,
        SCALES.DEEP_ACQUIRING.SUBSCALES.SEEKING_CLARITY_NAME,
        SCALES.DEEP_CONSOLIDATION.SUBSCALES.SELF_VERBALIZATION_NAME,
        SCALES.TRANSFER.SUBSCALES.SIMILARITIES_DIFFERENCES_NAME
      ]
    },

    yAxis: {
      min: 0,
      title: {
        text: 'Total number of students'
      }
    },
    legend: {
      backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
    },
    tooltip: {
      formatter: function () {
        return (
          '<b>' +
          this.x +
          '</b><br/>' +
          this.series.name +
          ': ' +
          this.y +
          '<br/>' +
          'Total: ' +
          this.point.stackTotal
        )
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Math.round((100 * this.y) / this.total) + '%'
          },
          color: Highcharts.theme && Highcharts.theme.dataLabelsColor
        }
      }
    },
    series: [
      {
        name: '80th',
        color: '#E5F6EC',
        data: stackBarData[2]
      },
      {
        name: '50th',
        color: '#FFFFDD',
        data: stackBarData[1]
      },
      {
        name: '20th',
        color: '#FFE5E6',
        data: stackBarData[0]
      }
    ]
  }

  useError(error)

  useEffect(() => {
    if (!form && !error) {
      dispatch(getForm())
      if (!formAnswerGid && gid) {
        dispatch(getFormAnswerGid({ formId: form._id, gid: gid }))
      }
    }
  }, [dispatch, error, form, formAnswerGid, gid])

  useEffect(() => {
    if (!formAnswerGidAvg && !isGettingFormAnswerGidAvg && !error) {
      if (gid) {
        dispatch(getFormAnswerGidAvg({ formId: form._id, gid: gid }))
      }
    }
  }, [dispatch, error, form._id, formAnswerGidAvg, gid, isGettingFormAnswerGidAvg])

  useEffect(() => {
    if (formAnswerGidAvg && !isGettingFormAnswerGidAvg && !error) {
      setStackBarData(computeStackBar(formAnswerGidAvg))
      setGroupName(formAnswerGid.groupName)
    }
  }, [error, formAnswerGid.groupName, formAnswerGidAvg, isGettingFormAnswerGidAvg])

  return (
    <Box
      sx={{
        flex: 4
      }}
    >
      <Typography variant='h5' sx={{ marginTop: '20px', marginLeft: '20px' }}>
        Stacked Column Report {groupName ? ` - Group: ${groupName}` : ''}
      </Typography>
      <Box
        sx={{
          margin: '20px',
          padding: '20px',
          WebkitBoxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)',
          boxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)'
        }}
      >
        <HighchartsReact highcharts={Highcharts} options={stackedChartptions} />
      </Box>
      <Box
        sx={{
          margin: '20px',
          padding: '20px',
          WebkitBoxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)',
          boxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.75)'
        }}
      >
        <Grid item sx={{ display: 'flex', alignContent: 'right', width: '100%' }} xs={12}>
          <Accordion sx={{ width: '100%', marginBottom: '5px' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                Detailed Breakdown
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HeatMapTeacherReport gid={gid} />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <ReportFooter typofAlign='right' typofColor='#2b2b2b' />
      </Box>
    </Box>
  )
}

StackedTeacherReport.propTypes = {
  gid: PropTypes.string,
  sid: PropTypes.string,
  formAnswerId: PropTypes.string
}

export default StackedTeacherReport
