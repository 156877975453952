import BaseApi from './base'
/**
 * This wraps multiple auth api requests to the server.
 */
class AuthApi extends BaseApi {
  /**
   * Log in
   * @param {string} email
   * @param {string} password
   * @return {Promise<*>}
   */
  async login({ email, password }) {
    const _self = this
    try {
      const body = {
        email,
        password
      }
      const res = await _self.client.post(_self.endpoints.AUTH_LOGIN, body)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Get session status for current user.
   * @return {Promise<*>}
   */
  async getSessionStatus() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.GET_SESSION_STATUS)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Logout.
   * @return {Promise<*>}
   */
  async logout() {
    const _self = this
    try {
      const res = await _self.client.post(_self.endpoints.AUTH_LOGOUT)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Register new user.
   * @param {String} email
   * @param {String} password
   * @param {String} firstName
   * @param {String} lastName
   * @param {String} role
   * @return {Promise<*>}
   */
  async registerUser({ email, password, firstName, lastName, role }) {
    const _self = this
    try {
      const body = {
        email,
        password,
        firstName,
        lastName,
        role
      }
      const res = await _self.client.post(_self.endpoints.AUTH_REGISTER, body)

      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Send reset password email.
   * @param {String} email
   * @return {Promise<*>}
   */
  async emailResetPassword({ email }) {
    const _self = this
    try {
      const body = {
        email
      }
      const res = await _self.client.post(_self.endpoints.EMAIL_RESET_PASSWORD, body)

      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  /**
   * Reset password.
   * @param {String} token
   * @param {String} userId
   * @param {String} password
   * @return {Promise<*>}
   */
  async resetPassword({ userId, password, token }) {
    const _self = this
    try {
      const body = {
        userId,
        password,
        token
      }
      const res = await _self.client.post(_self.endpoints.RESET_PASSWORD, body)

      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
}

export default AuthApi
