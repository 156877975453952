import BaseApi from './base'

class FormApi extends BaseApi {
  async getForms() {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.FORMS)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async getFormAnswer({ formId, formAnswerId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.FORM_ANSWER({ formId, formAnswerId }))
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async getFormAnswerIndividual({ formId, formAnswerId }) {
    const _self = this
    try {
      const res = await _self.client.get(
        _self.endpoints.FORM_ANSWER_INDIVIDUAL({ formId, formAnswerId })
      )
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async getFormAnswerAvg({ formId }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.GET_FORM_ANSWER_AVG({ formId }))
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async getFormAnswerSidGid({ formId, sid, gid }) {
    const _self = this
    try {
      const res = await _self.client.get(
        _self.endpoints.GET_FORM_ANSWER_SID_GID({ formId, sid, gid })
      )
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async getFormAnswerGid({ formId, gid }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.GET_FORM_ANSWER_GID({ formId, gid }))
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async getFormAnswerGidStudentsName({ formId, gid }) {
    const _self = this
    try {
      const res = await _self.client.get(
        _self.endpoints.GET_FORM_ANSWER_GID_STUDENT_NAME({ formId, gid })
      )
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async getFormAnswerGidAvg({ formId, gid }) {
    const _self = this
    try {
      const res = await _self.client.get(_self.endpoints.GET_FORM_ANSWER_GID_AVG({ formId, gid }))
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async checkSurveyStatus({ formId, sid, gid }) {
    const _self = this
    try {
      const params = new URLSearchParams([
        ['sid', sid],
        ['gid', gid]
      ])
      const res = await _self.client.get(_self.endpoints.FORM_ANSWER_HAS_COMPLETED({ formId }), {
        params
      })
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async createFormAnswer({ formId, answer, userId, groupId }) {
    const _self = this
    try {
      const body = {
        answer
      }
      if (userId) {
        body.userId = userId
      }
      if (groupId) {
        body.groupId = groupId
      }
      const res = await _self.client.post(_self.endpoints.FORM_ANSWERS({ formId }), body)
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
  async updateFormAnswer({ formId, formAnswerId, answer, completed }) {
    const _self = this
    try {
      const body = {
        answer,
        completed
      }
      const res = await _self.client.put(
        _self.endpoints.FORM_ANSWER({ formId, formAnswerId }),
        body
      )
      return _self.postProcessor(res)
    } catch (err) {
      _self.errorHandler(err)
    }
  }
}

export default FormApi
