import { React } from 'react'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import VersionUI from '@/components/versionUI'
import PageLogo from '@/components/pageLogo'
import SurveyConsent from '@/components/surveyConsent'

const IndividualConsentPage = () => {
  return (
    <Container maxWidth='md' sx={{ height: '100vh' }}>
      <Box sx={{ mt: '1em', mb: '1em' }}>
        <Grid container>
          <Grid item xs={4} justifyContent='flex-start'>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PageLogo />
            </Box>
          </Grid>
          <Grid item xs={4} container justifyContent='center'>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant='h4'
                align='center'
                sx={{ color: (theme) => theme.palette.primary.contrastText }}
              >
                Individual Consent
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <SurveyConsent />
        </Grid>
      </Grid>
      <VersionUI typoAlign='right' typoColor='#F4F4F4' />
    </Container>
  )
}
export default IndividualConsentPage
