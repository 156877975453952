const ROOT_ROUTES = {
  ALL: '*',
  LANDING_PAGE: '/home',
  SURVEY_INSTRUCTIONS_PAGE: '/survey-instructions',
  FORM_ANSWERS: '/form-answers'
}

const CONSENT_ROUTES = {
  INDIVIDUAL_CONSENT_PAGE: '/consent/individual',
  STUDENT_CONSENT_PAGE: '/consent/student'
}

const FORM_ANSWERS_ROUTES = {
  NEW_FORM_ANSWER: '/form-answers/new',
  EXIST_FORM_ANSWER: '/form-answers/:formAnswerId'
}

const TEACHER_ACCESS = {
  LOGIN: '/teacher-access/login',
  CREATE_ACCOUNT: '/teacher-access/create-account',
  FORGOT_PASSWORD: '/teacher-access/forgot-password',
  RESET_PASSWORD: '/reset-password',
  TEACHER_DASHBOARD: '/teacher-access/dashboard'
}

const TEACHER_DASHBOARD = {
  NEW_GROUP: '/dashboard/new-group',
  EDIT_GROUP: '/dashboard/edit-group/:id'
}

const REPORTS_ROUTES = {
  REPORTS_DASHBOARD: '/reports/dashboard/',
  TEACHER_REPORTS_DASHBOARD: '/teacher-reports/dashboard/:gid'
}

export {
  ROOT_ROUTES,
  CONSENT_ROUTES,
  FORM_ANSWERS_ROUTES,
  TEACHER_ACCESS,
  TEACHER_DASHBOARD,
  REPORTS_ROUTES
}
