import React from 'react'
import { useHistory } from 'react-router-dom'

import { ROOT_ROUTES } from '@/constants/routes'
import logo from '@/assets/img/logo.png'
import PropTypes from 'prop-types'

const MainLogo = (props) => {
  const history = useHistory()
  const { gid, sid } = props

  const onClickImage = () => {
    if (!sid && !gid) {
      history.push(ROOT_ROUTES.LANDING_PAGE)
    }
  }

  return (
    <div>
      <img src={logo} alt='logo' width='300' onClick={onClickImage} style={{ cursor: 'pointer' }} />
    </div>
  )
}
MainLogo.propTypes = {
  gid: PropTypes.string,
  sid: PropTypes.string
}
export default MainLogo
