import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { useError } from '@/libs/hooks'
import { resetPassword } from '@/store/actions/auth'

import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { TEACHER_ACCESS } from '@/constants/routes'
import { REDUCER_STATE } from '@/constants/store'
import { ERROR_MESSAGES } from '@/constants/errors'
import { REGEX } from '@/constants/regex'
import MainLogo from '@/components/mainLogo'
import CopyRight from '@/components/copyRight'
import Loading from '@/components/loading'
import SuccessChangePassword from '@/components/successChangePassword'
import VersionUI from '@/components/versionUI'

const ResetPasswordPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const queryParams = queryString.parse(search)

  const [newPassword, setnewPassword] = useState({
    password: '',
    passwordConfirmation: ''
  })

  const [submitFlag, setsubmitFlag] = useState(false)

  const isResettingPassword = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.IS_RESETTING_PASSWORD]
  )

  const error = useSelector(
    (state) => state[REDUCER_STATE.AUTH.NAME][REDUCER_STATE.AUTH.FIELDS.ERROR]
  )

  const prevIsResettingPassword = useRef(isResettingPassword)

  const { password } = newPassword
  const userId = queryParams.userId
  const token = queryParams.token

  const [formErrors, setFormErrors] = useState({
    password: '',
    passwordConfirmation: ''
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setnewPassword({ ...newPassword, [name]: value })
    setFormErrors({ ...formErrors, [name]: '' })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClickSubmit(event)
    }
  }

  const onClickSubmit = () => {
    if (validateFields()) {
      dispatch(resetPassword({ userId, token, password }))
    }
  }

  const onClickBack = () => {
    history.push(TEACHER_ACCESS.LOGIN)
  }

  const validateFields = () => {
    let result = true
    // Validate Password
    let passwordError = ''
    if (newPassword.password === '') {
      passwordError = ERROR_MESSAGES.EMPTY_FIELD_ERROR({ paramName: 'Password' })
      result = false
    } else if (!REGEX.PASSWORD_VALIDATOR.test(newPassword.password)) {
      passwordError = ERROR_MESSAGES.INVALID_PASSWORD
      result = false
    }
    // Validate Password Confirmation
    let passwordErrorConfirmation = ''
    if (newPassword.password !== newPassword.passwordConfirmation) {
      passwordErrorConfirmation = ERROR_MESSAGES.INVALID_MATCH_PASSWORD
      result = false
    }

    setFormErrors({
      password: passwordError,
      passwordConfirmation: passwordErrorConfirmation
    })
    return result
  }

  useError(error)

  useEffect(() => {
    if (prevIsResettingPassword.current && !isResettingPassword && !error) {
      setsubmitFlag(true)
    }
    prevIsResettingPassword.current = isResettingPassword
  }, [isResettingPassword, error])

  return (
    <Container sx={{ height: '100vh' }} maxWidth='sm'>
      <Grid container component='main' direction='column' alignItems='center'>
        <Grid item xs={12}>
          <Box
            sx={{
              mt: '2em',
              mb: '2em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <MainLogo />
            <Typography
              variant='h5'
              align='center'
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            >
              Enter a new password for your account
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ borderRadius: '8px' }} component={Paper} elevation={15}>
          <Box sx={{ p: 2, margin: (theme) => theme.spacing(2) }}>
            {!isResettingPassword ? (
              submitFlag ? (
                <SuccessChangePassword />
              ) : (
                <Grid container alignItems='center' spacing={0}>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      name='password'
                      label='Password'
                      type='password'
                      id='password'
                      autoComplete='new-password'
                      autoFocus
                      onChange={handleChange}
                      {...(formErrors.password && {
                        error: true,
                        helperText: formErrors.password
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      name='passwordConfirmation'
                      label='Confirm Password'
                      type='password'
                      id='passwordConfirmation'
                      autoComplete='new-password'
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      {...(formErrors.passwordConfirmation && {
                        error: true,
                        helperText: formErrors.passwordConfirmation
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      sx={{ margin: (theme) => theme.spacing(2, 0, 1) }}
                      onClick={onClickSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth variant='text' color='primary' onClick={onClickBack}>
                      Back to login
                    </Button>
                  </Grid>
                </Grid>
              )
            ) : (
              <Loading fullScreen={false} tip='Resetting password...' />
            )}
            <Grid item xs={12}>
              <CopyRight />
            </Grid>
            <Grid item xs={12}>
              <VersionUI typoAlign='center' typoColor='textSecondary' />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
export default ResetPasswordPage
